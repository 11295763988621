import React, { useEffect, useState } from 'react';
import { GlobalContext } from '../../context/AppContext';
import QRCode from 'react-qr-code';
import $ from 'jquery';
import axios from 'axios';

const onlTransApi = '/server2/online_transation';

const Deposit = () => {

const { upi_data, bank_data, userActive, tranData, GetTranData } = GlobalContext();
const [viewPay, setViewPay] = useState('upi');
var [currPayUpi, setCurrPayUpi] = useState([]);
var [currPayBank, setCurrPayBank] = useState([]);
var [currDepAmt, setCurrDepAmt] = useState(0);
const [activeImg, setActiveImg] = useState(null);
const [pendingDepo, setPendingDepo] = useState([]);

const closePreviewImg = () => {
setActiveImg(null);   
}

const autoChanAmtDep = (val) => {
if(val <= 0){
setCurrDepAmt(0);
}
else{
setCurrDepAmt(Math.round(val));
}
}

const currDepoMin = () => {
var depoAmt = currDepAmt;
if(depoAmt <= 0){
setCurrDepAmt(0);
}
else{
setCurrDepAmt(parseFloat(depoAmt) - 1);
}
}

const currDepoMax = () => {
var depoAmt = currDepAmt;
setCurrDepAmt(parseFloat(depoAmt) + 1);
}

const setPayStake = (amt) => {
var currAmtState = currDepAmt;
setCurrDepAmt(parseFloat(currAmtState) + parseFloat(amt));
}

const depositUpi = async() => {
var formData = new FormData();
var upiAcName = currPayUpi.length > 0 ? currPayUpi[0].upiAcName : null;
var upiAcId = currPayUpi.length > 0 ? currPayUpi[0].upiAcId : null;
var receiver = currPayUpi.length > 0 ? currPayUpi[0].customer : null;
var sender = userActive.length > 0 ? userActive[0].customer : null;
var sender_role = userActive.length > 0 ? userActive[0].user_role : null;
var depositAmt = currDepAmt;
var paymentStatus = 'pending';
var paymentType = 'upi';
var paymentMethod = 'deposit';
var imgFile = activeImg;

if(depositAmt <= 0){
$('#msgAlert').fadeIn().addClass('danger').removeClass('success').html('deposit amount is 0');
setTimeout(() => {
$('#msgAlert').hide();
},1000);
return false;
}
if(imgFile === null){
$('#msgAlert').fadeIn().addClass('danger').removeClass('success').html('payment screenshot required');
setTimeout(() => {
$('#msgAlert').hide();
},1000);
return false;
}
else{
formData.append('upiAcName', upiAcName);
formData.append('upiAcId', upiAcId);
formData.append('receiver', receiver);
formData.append('sender', sender);
formData.append('sender_role', sender_role);
formData.append('depositAmt', depositAmt);
formData.append('paymentStatus', paymentStatus);
formData.append('paymentType', paymentType);
formData.append('paymentMethod', paymentMethod);
formData.append('upiImg', imgFile);

try {
const sendData = await axios.post('/server2/deposit_online', formData,{
headers: {'Content-Type': 'multipart/form-data'}
});
const resData = await sendData.data;
if(resData === 'success'){
$('#msgAlert').fadeIn().addClass('success').removeClass('danger').html('deposit request submited successfully');
setTimeout(() => {
$('#msgAlert').hide();
setActiveImg(null);
setCurrDepAmt(0);
GetTranData(onlTransApi);
},1000);
return false;
}
else{
$('#msgAlert').fadeIn().addClass('danger').removeClass('success').html(resData);
setTimeout(() => {
$('#msgAlert').hide();
},1000);
}
} catch (error) {
console.log('backend deposit_online error : ' + error);
}
}
}

const dpositPayOnline = async() => {
var formData = new FormData();
var bankName = currPayBank.length > 0 ? currPayBank[0].bankName : null;
var holderName = currPayBank.length > 0 ? currPayBank[0].holderName : null;
var bankAcNo = currPayBank.length > 0 ? currPayBank[0].bankAcNo : null;
var bankIfsc = currPayBank.length > 0 ? currPayBank[0].bankIfsc : null;
var receiver = currPayBank.length > 0 ? currPayBank[0].customer : null;
var sender = userActive.length > 0 ? userActive[0].customer : null;
var sender_role = userActive.length > 0 ? userActive[0].user_role : null;
var depositAmt = currDepAmt;
var paymentStatus = 'pending';
var paymentType = 'bank';
var paymentMethod = 'deposit';
var imgFile = activeImg;

if(imgFile === null){
$('#msgAlert').fadeIn().addClass('danger').removeClass('success').html('payment screenshot required');
setTimeout(() => {
$('#msgAlert').hide();
},1000);
return false;
}
else{
formData.append('bankName', bankName);
formData.append('holderName', holderName);
formData.append('bankAcNo', bankAcNo);
formData.append('bankIfsc', bankIfsc);
formData.append('receiver', receiver);
formData.append('sender', sender);
formData.append('sender_role', sender_role);
formData.append('depositAmt', depositAmt);
formData.append('paymentStatus', paymentStatus);
formData.append('paymentType', paymentType);
formData.append('paymentMethod', paymentMethod);
formData.append('upiImg', imgFile);

try {
const sendData = await axios.post('/server2/deposit_online', formData,{
headers: {'Content-Type': 'multipart/form-data'}
});
const resData = await sendData.data;
if(resData === 'success'){
$('#msgAlert').fadeIn().addClass('success').removeClass('danger').html('deposit request submited successfully');
setTimeout(() => {
$('#msgAlert').hide();
setActiveImg(null);
setCurrDepAmt(0);
GetTranData(onlTransApi);
},1000);
return false;
}
else{
$('#msgAlert').fadeIn().addClass('danger').removeClass('success').html(resData);
setTimeout(() => {
$('#msgAlert').hide();
},1000);
}
} catch (error) {
console.log('backend deposit_online_bank error : ' + error);
}
}
}

const depositOlineCancel = () => {
setCurrDepAmt(0);
setActiveImg(null);
}

useEffect(() => {
$(document).on('click', '.payOptBtn', function(){
$(this).addClass('active').siblings().removeClass('active');
});

if(tranData.length > 0 && userActive.length > 0){
setPendingDepo(tranData.filter((e) => e.sender === userActive[0].customer && e.paymentStatus === 'pending' && e.paymentMethod === 'deposit'));
}

},[tranData, userActive]);

useEffect(() => {
if(viewPay === 'upi' && upi_data.length > 0 && userActive.length > 0){
setActiveImg(null);   
setCurrPayUpi(upi_data.filter((e) => e.customer === userActive[0].creator &&  currDepAmt >= e.upiMin && currDepAmt <= e.upiMax));
}
else if(viewPay === 'bank' && bank_data.length > 0 && userActive.length > 0){
setActiveImg(null);   
setCurrPayBank(bank_data.filter((e) => e.customer === userActive[0].creator &&  currDepAmt >= e.depoBankMin && currDepAmt <= e.depoBankMax));
}
},[viewPay, upi_data, bank_data, userActive, currDepAmt]);

useEffect(() => {
GetTranData(onlTransApi);
// eslint-disable-next-line react-hooks/exhaustive-deps
},[]);

return (
<>
{
pendingDepo.length > 0 ?
<div className='pendingPayDiv'>
<div className='pendingContent'>
<img src='/images/pending.png' alt='pending' />
<span>waiting for deposit confirmaition</span>
<span>after few minutes your deposit balance will be reflected in your wallet</span>
</div>
</div>
:
<div className='paymentContents'>

<div className='depositPayBtnWrap'>
<div className='depositPayBtnHead'>
<span>wallet - deposit</span>
</div>

<div className='depositPayBtnDiv gridMobDepPay'>
<div className='col'>
<div className='payInputRanDiv'>
<span className='leftMin' onClick={currDepoMin}>-</span>
<input type='number' name='depoAmt' value={currDepAmt} placeholder='enter amount' onChange={(e) => autoChanAmtDep(e.target.value)} />
<span className='plusMax' onClick={currDepoMax}>+</span>
</div>
</div>
<div className='col'>
<div className='depositPayAutBtn'>
<button onClick={() => setPayStake(100)}>100+</button>
<button onClick={() => setPayStake(500)}>500+</button>
<button onClick={() => setPayStake(1000)}>1000+</button>
<button onClick={() => setPayStake(5000)}>5000+</button>
<button onClick={() => setPayStake(10000)}>10000+</button>
<button onClick={() => setPayStake(100000)}>100000+</button>
</div>
</div>
</div>
</div>

<div className='depositPayBtnWrap'>
<div className='depositPayBtnHead'>
<span>payment options</span>
</div>

<div className='depositPayBtnDiv'>
<button className='payOptBtn active' onClick={() => setViewPay('upi')}>upi</button>
<button className='payOptBtn' onClick={() => setViewPay('bank')}>bank ac</button>
</div>
</div>

{
viewPay === 'upi' && currPayUpi.length > 0 ?
<div className='depositPayBtnWrap'>
<div className='depositPayBtnHead'>
<span>upi options</span>
</div>

<div className='depositPayBtnDiv'>

<div className='qrDivWrap'>
<div className='qrTitles'>
<span>transfer amount</span>
<span>rs. {currDepAmt.toFixed(2)}/-</span>
<span>scan by any upi app & pay the amount</span>
</div>

<div className='upiWhatDiv'>
{
currPayUpi[0].upiType === 'phonepay' ?
<div className='upiImgWhat'>
<img src='/images/ppay.png' alt='ppay' />
</div>
: currPayUpi[0].upiType === 'gpay' ?
<div className='upiImgWhat'>
<img src='/images/gpay.png' alt='gpay' />
</div>
: currPayUpi[0].upiType === 'paytm' ?
<div className='upiImgWhat'>
<img src='/images/paytm.png' alt='paytm' />
</div>
:
<div className='upiImgWhat'>
<img src='/images/bhim1.png' alt='bhim' />
</div>
}
</div>

<div className='qrDiv'>
<QRCode 
size={256}
style={{ height: "auto", maxWidth: "100%", width: "100%" }}
value={`upi://pay?pa=${currPayUpi[0].upiAcId}&pn=${currPayUpi[0].upiAcName}&tn=cftrhwetaw4gta&am=${currDepAmt}`}
viewBox={`0 0 256 256`}
/>
</div>

<div className='qrMinMaxDiv'>
<span>min : {currPayUpi[0].upiMin} max : {currPayUpi[0].upiMax}</span>
</div>

<div className='qrHolderDeta'>
<div className='qrHolderForm'>
<label>account name</label>
<input type='text' value={currPayUpi[0].upiAcName} disabled />
</div>
<div className='qrHolderForm'>
<label>upi id</label>
<input type='text' value={currPayUpi[0].upiAcId} disabled />
</div>
<div className='qrHolderForm'>
<label>amount</label>
<input type='number' value={currDepAmt} disabled />
</div>
{
activeImg === null ?
<div className='qrHolderFile'>
<label><i className='fa fa-plus' />
<input type='file' name='paySS' id='paySS' accept='image/*' onChange={(e) => setActiveImg(e.target.files[0])} />
click here to upload payment screenshot</label>
</div>
:
<div className='previewImgWrap'>
<i className='fa fa-close' onClick={closePreviewImg} />
<div className='previewImg'>
<img src={URL.createObjectURL(activeImg)} alt='previewImg' />
</div>
</div>
}
</div>

<div className='qrTerms'>
<div className='col'>
<i className='fa fa-exclamation-circle' />
</div>
<div className='col'>
<span>Once you make the payment to the above account, Share payment transation screenshot below and confirm successful deposit.</span>
</div>
</div>

<div className='qrOptBtn'>
<button onClick={depositUpi} className='qrSubmit'>submit</button>
<button onClick={depositOlineCancel} className='qrCancel'>cancel</button>
</div>

<div className='qrFooterImg'>
<img src='/images/upipayment.png' alt='upipayment' />
</div>

</div>

</div>
</div>
: viewPay === 'upi' &&
<div className='depositPayBtnWrap'>
<div className='depositPayBtnHead'>
<span>banking options</span>
</div>

<div className='gridDiv gridDivNoRec'>
<img src='/images/upi.png' alt='upi' />
<span>no upi record found</span>
<span>try other transation amount. transation amount limit exceed.</span>
</div>
</div>
}

{
viewPay === 'bank' && currPayBank.length > 0 ?
<div className='depositPayBtnWrap'>
<div className='depositPayBtnHead'>
<span>banking options</span>
</div>

<div className='gridDiv'>
<div className='qrDivWrap bankViewData'>

<div className='bankWhatDiv'>
<div className='bankImgWhat'>
<img src='/images/bank.png' alt='bank' />
<span>banking details</span>
</div>
</div>

<div className='qrMinMaxDiv'>
<span>min : {currPayBank[0].depoBankMin} max : {currPayBank[0].depoBankMax}</span>
</div>

<hr className='whiteHr' />

<div className='qrHolderDeta'>
<div className='qrHolderForm'>
<label>bank name</label>
<input type='text' value={currPayBank[0].bankName} disabled />
</div>
<div className='qrHolderForm'>
<label>holder name</label>
<input type='text' value={currPayBank[0].holderName} disabled />
</div>
<div className='qrHolderForm'>
<label>account no.</label>
<input type='text' value={currPayBank[0].bankAcNo} disabled />
</div>
<div className='qrHolderForm'>
<label>ifsc code</label>
<input type='text' value={currPayBank[0].bankIfsc} disabled />
</div>
<div className='qrHolderForm'>
<label>amount</label>
<input type='number' value={currDepAmt} disabled />
</div>
{
activeImg === null ?
<div className='qrHolderFile'>
<label><i className='fa fa-plus' />
<input type='file' name='paySS' id='paySS' accept='image/*' onChange={(e) => setActiveImg(e.target.files[0])} />
click here to upload payment screenshot</label>
</div>
:
<div className='previewImgWrap'>
<i className='fa fa-close' onClick={closePreviewImg} />
<div className='previewImg'>
<img src={URL.createObjectURL(activeImg)} alt='previewImg' />
</div>
</div>
}
</div>

<div className='qrTerms'>
<div className='col'>
<i className='fa fa-exclamation-circle' />
</div>
<div className='col'>
<span>Once you make the payment to the above account, Share payment transation screenshot below and confirm successful deposit.</span>
</div>
</div>

<div className='bankOptBtn'>
<button onClick={dpositPayOnline} className='bankSubmit'>submit</button>
</div>

</div>

</div>
</div>
: viewPay === 'bank' &&
<div className='depositPayBtnWrap'>
<div className='depositPayBtnHead'>
<span>banking options</span>
</div>

<div className='gridDiv gridDivNoRec'>
<img src='/images/bank.png' alt='bank' />
<span>no bank record found</span>
<span>try other transation amount. transation amount limit exceed.</span>
</div>
</div>
}

</div>
}
</>
)
}

export default Deposit;
