import React, { useEffect } from 'react';
import $ from 'jquery';
import axios from 'axios';
import { GlobalContext } from '../context/AppContext';

const upiDataApi = '/server2/upi_data';

const DelUpi = ({delCurrUpi, setDelCurrUpi}) => {

const { GetUpiData } = GlobalContext();

const closeDelUpi = () => {
$('#delUpiModal').hide();
setDelCurrUpi(null);
}

const removeUpiConf = async() => {
var id = delCurrUpi;
try {
const sendData = await axios.post('/server2/remove_upi', {id: id});
const resData = await sendData.data;
if(resData === 'success'){
$('#msgAlert').fadeIn().addClass('success').removeClass('danger').html('upi removed successfully');
setTimeout(() => {
$('#msgAlert').hide();
GetUpiData(upiDataApi);
$('#delUpiModal').hide();
setDelCurrUpi(null);
},1000);
return false;
}
else{
$('#msgAlert').fadeIn().addClass('danger').removeClass('success').html(resData);
setTimeout(() => {
$('#msgAlert').hide();
},1000);  
}
} catch (error) {
console.log('backend remove_upi error : ' + error);
}
}

useEffect(() => {
if(delCurrUpi !== null){
$('#delUpiModal').show();
}
},[delCurrUpi]);

return (
<>
<div className='modalsSm' id='delUpiModal'>
<div className='modalsSm_content'>
<div className='modalsSm_header'><span>remove upi details</span> <i className='fa fa-close right' onClick={() => closeDelUpi()}></i></div>
<div className='modalsSm_body'>

<div className='formDiv formDivRemove'>
<img src='/images/bin.png' alt='bin' />
<span>are you sure? you want to remove this upi details?</span>
<button onClick={() => removeUpiConf()}>yes</button>
<button onClick={() => closeDelUpi()}>no</button>
</div>

</div>
</div>
</div>
</>
)
}

export default DelUpi;
