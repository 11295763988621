import React from 'react';
import $ from 'jquery';
import axios from 'axios';
import { GlobalContext } from '../context/AppContext';

const bankDataApi = '/server2/bank_data';

const AddBank = () => {

const { userActive, GetBankData } = GlobalContext();

const closeAddBank = () => {
$('#addBankModal').hide();
$('.paymentInput input').val('');
$('.paymentInput select option').prop('selected', null);
}

const depoMinChangeBank = (val) => {
if(val <= 0){
$('#depoBankMin').val(0);
}
}

const depoMaxChangeBank = (val) => {
if(val <= 0){
$('#depoBankMax').val(1);
}
}

const addBankData = async() => {
var bankName = $('#bankName').val();
var holderName = $('#bankAcName').val();
var bankAcNo = $('#bankAcNo').val();
var bankIfsc = $('#bankIfsc').val();
var depoBankMin = $('#depoBankMin').val();
var depoBankMax = $('#depoBankMax').val();
var customer = userActive.length > 0 && userActive[0].customer;
var user_role = userActive.length > 0 && userActive[0].user_role;
var creator = userActive.length > 0 && userActive[0].creator;

if(bankName === ''){
$('#msgAlert').fadeIn().addClass('danger').removeClass('success').html('bank name required');
setTimeout(() => {
$('#msgAlert').hide();
},1000);
return false;
}
if(holderName === ''){
$('#msgAlert').fadeIn().addClass('danger').removeClass('success').html('holder name required');
setTimeout(() => {
$('#msgAlert').hide();
},1000);
return false;
}
if(bankAcNo === ''){
$('#msgAlert').fadeIn().addClass('danger').removeClass('success').html('account number required');
setTimeout(() => {
$('#msgAlert').hide();
},1000);
return false;
}
if(bankIfsc === ''){
$('#msgAlert').fadeIn().addClass('danger').removeClass('success').html('ifsc code required');
setTimeout(() => {
$('#msgAlert').hide();
},1000);
return false;
}
if(depoBankMin <= 0){
$('#msgAlert').fadeIn().addClass('danger').removeClass('success').html('min. deposit required');
setTimeout(() => {
$('#msgAlert').hide();
},1000);
return false;
}
if(depoBankMax <= 0){
$('#msgAlert').fadeIn().addClass('danger').removeClass('success').html('max. deposit required');
setTimeout(() => {
$('#msgAlert').hide();
},1000);
return false;
}
if(parseFloat(depoBankMin) >= parseFloat(depoBankMax)){
$('#msgAlert').fadeIn().addClass('danger').removeClass('success').html('min lower than max');
setTimeout(() => {
$('#msgAlert').hide();
},1000);
return false;
}
else{
try {
const sendData = await axios.post('/server2/add_bank', {
bankName : bankName,
holderName : holderName,
bankAcNo : bankAcNo,
bankIfsc : bankIfsc,
depoBankMin : depoBankMin,
depoBankMax : depoBankMax,
customer : customer,
user_role : user_role,
creator : creator
});
const resData = await sendData.data;
if(resData === 'success'){
$('#msgAlert').fadeIn().addClass('success').removeClass('danger').html('bank add successfully');
setTimeout(() => {
$('#msgAlert').hide();
$('#addBankModal').hide();
$('.paymentInput input').val('');
GetBankData(bankDataApi);
},1000);
return false;
}
else{
$('#msgAlert').fadeIn().addClass('danger').removeClass('success').html(resData);
setTimeout(() => {
$('#msgAlert').hide();
},1000);    
}
} catch (error) {
console.log('backend add_bank_data error : ' + error);
}
}
}

return (
<>
<div className='modalsSm' id='addBankModal'>
<div className='modalsSm_content'>
<div className='modalsSm_header'><span>add bank details</span> <i className='fa fa-close right' onClick={() => closeAddBank()}></i></div>
<div className='modalsSm_body'>

<div className='paymentForm'>
<div className='paymentInput'>
<label>bank name *</label>
<input type='text' name='bankName' id='bankName' placeholder='bank name...' />
</div>

<div className='paymentInput'>
<label>bank holder name *</label>
<input type='text' name='bankAcName' id='bankAcName' placeholder='bank holder name...' />
</div>

<div className='paymentInput'>
<label>bank account no *</label>
<input type='text' name='bankAcNo' id='bankAcNo' placeholder='bank account number...' />
</div>

<div className='paymentInput'>
<label>IFSC code *</label>
<input type='text' name='bankIfsc' id='bankIfsc' placeholder='ifsc code...' />
</div>

<div className='paymentInput'>
<label>deposit min *</label>
<input type='number' name='depoBankMin' id='depoBankMin' placeholder='deposit min...' onChange={(e) => depoMinChangeBank(e.target.value)} />
</div>

<div className='paymentInput'>
<label>deposit max *</label>
<input type='number' name='depoBankMax' id='depoBankMax' placeholder='deposit max...'  onChange={(e) => depoMaxChangeBank(e.target.value)} />
</div>

<div className='paymentUpiBut'>
<button onClick={addBankData}>add bank</button>
</div>
</div>

</div>
</div>
</div>
</>
)
}

export default AddBank;
