

const Reducer = (state, action) => {
switch (action.type) {
case 'ACTIVE_USER_LOADING':
return{
...state,
userLoad : true,
userErr : false
}  

case 'ACTIVE_USER_SET':
return{
...state,
userLoad : false,
userErr : false,
userActive : action.payload
}  

case 'ACTIVE_USER_ERR':
return{
...state,
userLoad : false,
userErr : true
}  

case 'EXPO_USER_LOADING':
return{
...state,
expoLoad : true,
expoErr : false
}  

case 'EXPO_USER_SET':
return{
...state,
expoLoad : false,
expoErr : false,
useExpo : action.payload
}  

case 'EXPO_USER_ERR':
return{
...state,
expoLoad : false,
expoErr : true
}  

case 'USER_LIST_LOADING':
return{
...state,
userList_load : true,
userList_err : false
}  

case 'USER_LIST__SET':
return{
...state,
userList_load : false,
userList_err : false,
userList : action.payload
}  

case 'USER_LIST_ERR':
return{
...state,
userList_load : false,
userList_err : true
}  

case 'USER_BANKING_LOADING':
return{
...state,
userBank_load : true,
userBank_err : false
}  

case 'USER_BANKING_SET':
return{
...state,
userBank_load : false,
userBank_err : false,
userBank: action.payload
}  

case 'USER_BANKING_ERR':
return{
...state,
userBank_load : false,
userBank_err : true
}  

case 'USER_ACTIVITY_LOADING':
return{
...state,
usrAct_load : true,
usrAct_err : false
}  

case 'USER_ACTIVITY_SET':
return{
...state,
usrAct_load : false,
usrAct_err : false,
usrAct: action.payload
}  

case 'USER_ACTIVITY_ERR':
return{
...state,
usrAct_load : false,
usrAct_err : true
}  

case 'PROFIT_LOSS_LOADING':
return{
...state,
isProfitLoad: true,
isProfitErr: false
} 

case 'PROFIT_LOSS_SET':
return{
...state,
isProfitLoad: false,
isProfitErr: false,
profitLossUser: action.payload
}  

case 'PROFIT_LOSS_ERROR':
return{
...state,
isProfitLoad: false,
isProfitErr: true
} 

case 'USER_TOTAL_BAL_LOADING':
return{
...state,
loadTotalBal: true,
errTotalBal: false
} 

case 'USER_TOTAL_BAL_SET':
return{
...state,
loadTotalBal: false,
errTotalBal: false,
total_bal_Users: action.payload
} 

case 'USER_TOTAL_BAL_ERR':
return{
...state,
loadTotalBal: false,
errTotalBal: true
} 

case 'USER_ALL_LOADING':
return{
...state,
loadAllUser: true,
errAllUser: false
} 

case 'USER_ALL_SET':
return{
...state,
loadAllUser: false,
errAllUser: false,
allUsers: action.payload
} 

case 'USER_ALL_ERR':
return{
...state,
loadAllUser: false,
errAllUser: true
} 

case 'REMOVE_USER_LOADING':
return{
...state,
loadRemoveusr: true,
errRemoveUsr: false
} 

case 'REMOVE_USER_SET':
return{
...state,
loadRemoveusr: false,
errRemoveUsr: false,
removeUsersList: action.payload
} 

case 'REMOVE_USER_ERR':
return{
...state,
loadRemoveusr: false,
errRemoveUsr: true
} 

case 'UNSET_USER_LOADING':
return{
...state,
loadUnsetBets: true,
errUnsetBets: false
} 

case 'UNSET_USER_SET':
return{
...state,
loadUnsetBets: false,
errUnsetBets: false,
unsetBets: action.payload
} 

case 'UNSET_USER_ERR':
return{
...state,
loadUnsetBets: false,
errUnsetBets: true
} 

case 'CRICKET_MARKET_LOADING':
return{
...state,
isCricMarLoad: true,
isCricMarErr: false
} 

case 'CRICKET_MARKET_SET':
return{
...state,
isCricMarLoad: false,
isCricMarErr: false,
cricMarket: action.payload
} 

case 'CRICKET_MARKET_ERROR':
return{
...state,
isCricMarLoad: false,
isCricMarErr: true
} 

case 'SOCCER_MARKET_LOADING':
return{
...state,
isSoccMarLoad: true,
isSoccMarErr: false
} 

case 'SOCCER_MARKET_SET':
return{
...state,
isSoccMarLoad: false,
isSoccMarErr: false,
soccMarket: action.payload
} 

case 'SOCCER_MARKET_ERROR':
return{
...state,
isSoccMarLoad: false,
isSoccMarErr: true
} 

case 'TENNIS_MARKET_LOADING':
return{
...state,
isTennMarLoad: true,
isTennMarErr: false
} 

case 'TENNIS_MARKET_SET':
return{
...state,
isTennMarLoad: false,
isTennMarErr: false,
tennMarket: action.payload
} 

case 'TENNIS_MARKET_ERROR':
return{
...state,
isTennMarLoad: false,
isTennMarErr: true
} 

case 'NOTICE_LOADING':
return{
...state,
loadNotice: true,
loadNoticeErr: false
} 

case 'NOTICE_SET':
return{
...state,
loadNotice: false,
loadNoticeErr: false,
allNotice: action.payload
} 

case 'NOTICE_ERROR':
return{
...state,
loadNotice: false,
loadNoticeErr: true
} 

case 'BET_SETTING_LOADING':
return{
...state,
loadBetSet: true,
errBetSet: false
} 

case 'BET_SETTING_SET':
return{
...state,
loadBetSet: false,
errBetSet: false,
betSet: action.payload
} 

case 'BET_SETTING_ERROR':
return{
...state,
loadBetSet: false,
errBetSet: true
} 

case 'GAME_SETTING_LOADING':
return{
...state,
loadGameSet: true,
errGameSet: false
} 

case 'GAME_SETTING_SET':
return{
...state,
loadGameSet: false,
errGameSet: false,
gameSet: action.payload
} 

case 'GAME_SETTING_ERROR':
return{
...state,
loadGameSet: false,
errGameSet: true
}

case 'PASS_HIS_LOADING':
return{
...state,
loadPassHis: true,
errPassHis: false
}

case 'PASS_HIS_SET':
return{
...state,
loadPassHis: false,
errPassHis: false,
passHis: action.payload
}

case 'PASS_HIS_ERROR':
return{
...state,
loadPassHis: false,
errPassHis: true
}

case 'CRICKET_COMP_LOADING':
return{
...state,
loadCricComp: true,
errCricComp: false
} 

case 'CRICKET_COMP_SET':
return{
...state,
loadCricComp: false,
errCricComp: false,
cricCompAll: action.payload
} 

case 'CRICKET_COMP_ERROR':
return{
...state,
loadCricComp: false,
errCricComp: true
}

case 'SETTLE_ALLBETS_LOADING':
return{
...state,
loadAllBetsSettle: true,
errAllBetsSettle: false
} 

case 'SETTLE_ALLBETS_SET':
return{
...state,
loadAllBetsSettle: false,
errAllBetsSettle: false,
allBetsSettle: action.payload
} 

case 'SETTLE_ALLBETS_ERROR':
return{
...state,
loadAllBetsSettle: false,
errAllBetsSettle: true
} 

case 'UPI_DATA_LOADING':
return{
...state,
loadUpi: true,
errUpi: false
} 

case 'UPI_DATA_SET':
return{
...state,
loadUpi: false,
errUpi: false,
upi_data: action.payload
} 

case 'UPI_DATA_ERROR':
return{
...state,
loadUpi: false,
errUpi: true
} 

case 'BANK_DATA_LOADING':
return{
...state,
loadBank: true,
errBank: false
} 

case 'BANK_DATA_SET':
return{
...state,
loadBank: false,
errBank: false,
bank_data: action.payload
} 

case 'BANK_DATA_ERROR':
return{
...state,
loadBank: false,
errBank: true
} 

case 'TRANSATION_DATA_LOADING':
return{
...state,
loadTran: true,
errTran: false
} 

case 'TRANSATION_DATA_SET':
return{
...state,
loadTran: false,
errTran: false,
tranData: action.payload
} 

case 'TRANSATION_DATA_ERROR':
return{
...state,
loadTran: false,
errTran: true
} 

case 'WITHDRAW_LIMIT_DATA_LOADING':
return{
...state,
loadWithLimit: true,
errWithLimit: false
} 

case 'WITHDRAW_LIMIT_DATA_SET':
return{
...state,
loadWithLimit: false,
errWithLimit: false,
withLimit: action.payload
} 

case 'WITHDRAW_LIMIT_DATA_ERROR':
return{
...state,
loadWithLimit: false,
errWithLimit: true
} 

default: return state;
}
}

export default Reducer
