import React, { useEffect, useState } from 'react';
import $ from 'jquery';
import AddBank from '../../modals/AddBank';
import { GlobalContext } from '../../context/AppContext';
import ViewBank from '../../modals/ViewBank';
import DelBank from '../../modals/DelBank';

const Bank = () => {

const { bank_data, userActive } = GlobalContext();
const [currBank, setCurrBank] = useState([]);
const [currPage, setCurrPage] = useState(1);
const [sortPage, setSortPage] = useState(25);
const recordPage = sortPage;
const lastIndex = currPage * recordPage;
const firstIndex = lastIndex - recordPage;
const records = currBank.slice(firstIndex, lastIndex);
const pageLength = Math.ceil(currBank.length / recordPage);
const pageNumber = [...Array(pageLength + 1).keys()].slice(1);

const [viewCurrBank, setViewCurrBank] = useState(null);
const [delCurrBank, setDelCurrBank] = useState(null);

const prevPage = () =>{
if(parseInt(currPage) !== 1){
setCurrPage(parseInt(currPage) - 1);
}
}
    
const nextPage = () =>{
if(parseInt(currPage) !== parseInt(pageLength)){
setCurrPage(parseInt(currPage) + 1);
}
}

const handleSort = (e) =>{
setSortPage(e.target.value);
}

const addBank = () => {
$('#addBankModal').show();
}

useEffect(() => {
if(bank_data.length > 0 && userActive.length > 0){
setCurrBank(bank_data.filter((e) => e.customer === userActive[0].customer));
}
else{
setCurrBank([]);
}
}, [bank_data, userActive]);

return (
<>
<div className='paymentContents'>
<div className='rightBtn_pay'>
<button onClick={addBank}><i className='fa fa-plus' /> add bank</button>
</div>

<div className='paymentDataDiv'>
<div className='stateBody'>
<div className='sort-pageDiv'>
<div className='sortInput'>
<label>sort page :</label> 
<select className='sm-select' onChange={(e) => handleSort(e)}>
<option>25</option>
<option>50</option>
<option>100</option>
<option>250</option>
</select>
</div>
</div>

<div className='totalRecords_div'>
<span>display {records.length} records out of total {currBank.length} records...</span>
</div>

<div className='table-responsive'>
<table className='paymentTable'>
<thead>
<tr>
<th>bank name</th>
<th>holder name</th>
<th>account no.</th>
<th>ifsc code</th>
<th>deposit min</th>
<th>deposit max</th>
<th>created date</th>
<th>action</th>
</tr>
</thead>
<tbody>
{
records.length > 0 ? records.map((data, index) => {
return(
<tr key={index}>
<td>{data.bankName}</td>
<td>{data.holderName}</td>
<td>{data.bankAcNo}</td>
<td>{data.bankIfsc}</td>
<td>{data.depoBankMin}</td>
<td>{data.depoBankMax}</td>
<td>{data.dateTime}</td>
<td>
<button className='tdBtn' onClick={() => setViewCurrBank(data.id)}><i className='fa fa-eye' /></button>
<button className='tdBtn' onClick={() => setDelCurrBank(data.id)}><i className='fa fa-trash' /></button>
</td>
</tr>
)
})
:
<tr>
<td colSpan='9' className='tdNo'><span className='tdNoRecord'>no records founds...</span></td>
</tr>
}
</tbody>
</table>
</div>

<div className='pagiNation_wrap'>
<div className='paginationDrop'>
<div className='sortInput'>
<label>page no :</label>    
<select onChange={(e) => setCurrPage(e.target.value)}>
{
pageNumber.map((data, index) =>{
return(
<option key={index}>{data}</option>
)
})
}
</select>
</div>
</div>

<ul>
<li className='pagination-btn radiusLeft' onClick={() => prevPage()}>Prev</li>
<li><span className='pagination-items'>{pageNumber.length > 0 ? currPage : 0} of {pageNumber.length}</span></li>
<li className='pagination-btn radiusRight' onClick={() => nextPage()}>Next</li>
</ul>
</div>
</div>
</div>
</div>

<AddBank />
<ViewBank viewCurrBank={viewCurrBank} setViewCurrBank={setViewCurrBank} />
<DelBank delCurrBank={delCurrBank} setDelCurrBank={setDelCurrBank}  />
</>
)
}

export default Bank;
