import React, { useEffect, useState } from 'react';
import $ from 'jquery';
import { useLocation } from 'react-router-dom';
import { GlobalContext } from '../context/AppContext';
import PageSideMenu from '../extra/PageSideMenu';
import EditComm from '../modals/EditComm';
import Rolling from '../modals/Rolling';
import ChangePass from '../modals/ChangePass';

const PrfAccount = () => {

const location = useLocation();
const { allUsers, userActive } = GlobalContext();
const [currProfile, setCurrProfile] = useState([]);

const editCommision = (id) => {
$('#commisionModal').show();
$('#commId').val(id);
}

const editRolling = (id, user, fancy, casino, sportsbook, bookmaker, virtualsport) => {
$('#RollcommisionModal').show();
$('#rollUser').text(user);
$('#rollId').val(id);
$('#editFancy').val(fancy);
$('#editCasino').val(casino);
$('#editSportsBook').val(sportsbook);
$('#editBookmaker').val(bookmaker);
$('#editVirCas').val(virtualsport);
}

const editPassword = (id, user) => {
$('#changePassModal').show();
$('#changePassId').val(id);
$('#PassUser').val(user);
}

useEffect(() => {
if(allUsers.length > 0){
setCurrProfile(allUsers.filter((e) => e.customer.trim() === (location.state !== null ? location.state.name : null)));
}
},[allUsers, location.state]);

return (
<>
<div className='content-body'>
<div className='containers'>
<div className='rowWrap'>
<div className='col'>
<PageSideMenu />
</div>
<div className='col'>
<div className='profilePage'>
<div className='headerProfile'><span>account details</span></div>
{
currProfile.length > 0 && currProfile.map((data, index) => {
return (
<div className='profileBody' key={index}>
<div>
<div className='col'><span>name</span></div>
<div className='col2'><span>{data.customer}</span></div>
</div>
<div>
<div className='col'><span>commision</span></div>
<div className='col2'>
<span className='flexSpan'>{data.commision}
{
userActive.length > 0 && userActive[0].customer === data.creator &&
<i className='fa fa-edit leftIcon' onClick={() => editCommision(data.id)}></i>
}
</span>
</div>
</div>
{
userActive.length > 0 && userActive[0].customer === data.creator &&
<div>
<div className='col'><span>rolling commision</span></div>
<div className='col2'><i className='fa fa-edit' onClick={() => editRolling(data.id, data.customer, data.fancy, data.casino, data.sportsbook, data.bookmaker, data.virtualsport)}></i></div>
</div>
}
<div>
<div className='col'><span>currency</span></div>
<div className='col2'><span className='upper'>{data.currency}</span></div>
</div>
<div>
<div className='col'><span>partnership</span></div>
<div className='col2'><span>{data.partnership}</span></div>
</div>
<div>
<div className='col'><span>mobile number</span></div>
<div className='col2'><span>{data.MobileNumber}</span></div>
</div>
{
data.coming === 'online' &&
<div>
<div className='col'><span>mail id</span></div>
<div className='col2'><span style={{textTransform: 'lowercase'}}>{data.mailid}</span></div>
</div>
}
{
userActive.length > 0 && userActive[0].customer === data.creator &&
<div>
<div className='col'><span>password</span></div>
<div className='col2'><span className='passSample'>******** <i className='fa fa-edit' onClick={() => editPassword(data.id, data.customer)}></i></span></div>
</div>
}
</div>
) 
})
}
</div>
</div>
</div>
</div>
</div>

<EditComm />
<Rolling />
<ChangePass />
</>
)
}

export default PrfAccount;
