import React, { useEffect, useState } from 'react';
import $ from 'jquery';
import QRCode from "react-qr-code";
import { GlobalContext } from '../context/AppContext';

const ViewUpi = ({viewCurrUpi, setViewCurrUpi}) => {

const { upi_data } = GlobalContext();
const [currVupi, setCurrVupi] = useState([]);
const [activeImg, setActiveImg] = useState(null);

const closeViewUpi = () => {
$('#viewUpiModal').hide();
setViewCurrUpi(null);
setCurrVupi([]);
setActiveImg(null);
}

const closePreviewImg = () => {
setActiveImg(null);   
}

useEffect(() => {
if(viewCurrUpi !== null){
if(upi_data.length > 0){
setCurrVupi(upi_data.filter((e) => e.id === viewCurrUpi));
}
$('#viewUpiModal').show();
}
}, [viewCurrUpi, upi_data]);

return (
<>
<div className='modalsSm' id='viewUpiModal'>
<div className='modalsSm_content'>
<div className='modalsSm_header'><span>preview upi details</span> <i className='fa fa-close right' onClick={() => closeViewUpi()}></i></div>
<div className='modalsSm_body'>

{
currVupi.length > 0 &&
<div className='qrDivWrap'>
<div className='qrTitles'>
<span>transfer amount</span>
<span>rs. 1000.00/-</span>
<span>scan by any upi app & pay the amount</span>
</div>

<div className='upiWhatDiv'>
{
currVupi[0].upiType === 'phonepay' ?
<div className='upiImgWhat'>
<img src='/images/ppay.png' alt='ppay' />
</div>
: currVupi[0].upiType === 'gpay' ?
<div className='upiImgWhat'>
<img src='/images/gpay.png' alt='gpay' />
</div>
: currVupi[0].upiType === 'paytm' ?
<div className='upiImgWhat'>
<img src='/images/paytm.png' alt='paytm' />
</div>
:
<div className='upiImgWhat'>
<img src='/images/bhim1.png' alt='bhim' />
</div>
}
</div>

<div className='qrDiv'>
<QRCode 
size={256}
style={{ height: "auto", maxWidth: "100%", width: "100%" }}
value={`upi://pay?pa=${currVupi[0].upiAcId}&pn=${currVupi[0].upiAcName}&tn=cftrhwetaw4gta&am=1000`}
viewBox={`0 0 256 256`}
/>
</div>

<div className='qrMinMaxDiv'>
<span>min : {currVupi[0].upiMin} max : {currVupi[0].upiMax}</span>
</div>

<div className='qrHolderDeta'>
<div className='qrHolderForm'>
<label>account name</label>
<input type='text' defaultValue={currVupi[0].upiAcName} disabled />
</div>
<div className='qrHolderForm'>
<label>upi id</label>
<input type='text' defaultValue={currVupi[0].upiAcId} disabled />
</div>
<div className='qrHolderForm'>
<label>amount</label>
<input type='number' defaultValue='1000' disabled />
</div>
{
activeImg === null ?
<div className='qrHolderFile'>
<label><i className='fa fa-plus' />
<input type='file' name='paySS' id='paySS' accept='image/*' onChange={(e) => setActiveImg(e.target.files[0])} />
click here to upload payment screenshot</label>
</div>
:
<div className='previewImgWrap'>
<i className='fa fa-close' onClick={closePreviewImg} />
<div className='previewImg'>
<img src={URL.createObjectURL(activeImg)} alt='previewImg' />
</div>
</div>
}
</div>

<div className='qrTerms'>
<div className='col'>
<i className='fa fa-exclamation-circle' />
</div>
<div className='col'>
<span>Once you make the payment to the above account, Share payment transation screenshot below and confirm successful deposit.</span>
</div>
</div>

<div className='qrOptBtn'>
<button className='qrSubmit'>submit</button>
<button className='qrCancel'>cancel</button>
</div>

<div className='qrFooterImg'>
<img src='/images/upipayment.png' alt='upipayment' />
</div>

</div>
}

</div>
</div>
</div>
</>
)
}

export default ViewUpi;
