import React, { useEffect, useState } from 'react';
import $ from 'jquery';
import { GlobalContext } from '../../../context/AppContext';

const Transation = () => {

const { tranData, userActive } = GlobalContext();
const [trans, setTrans] = useState('self');
const [viewTran, setViewTran] = useState([]);
const [currPage, setCurrPage] = useState(1);
const [sortPage, setSortPage] = useState(25);
const recordPage = sortPage;
const lastIndex = currPage * recordPage;
const firstIndex = lastIndex - recordPage;
const records = viewTran.slice(firstIndex, lastIndex);
const pageLength = Math.ceil(viewTran.length / recordPage);
const pageNumber = [...Array(pageLength + 1).keys()].slice(1);
const [tranPType, setTranPType] = useState('all');
const [tranPSType, setTranPSType] = useState('all');

const prevPage = () =>{
if(parseInt(currPage) !== 1){
setCurrPage(parseInt(currPage) - 1);
}
}

const nextPage = () =>{
if(parseInt(currPage) !== parseInt(pageLength)){
setCurrPage(parseInt(currPage) + 1);
}
}

const handleSort = (e) =>{
setSortPage(e.target.value);
}

const searchUsersTran = (val) => {
setViewTran(tranData.filter((e) => e.sender.includes(val))); 
}

useEffect(() => {
$(document).on('click', '.optTranBtn span', function(){
$(this).addClass('active').siblings().removeClass('active');
});

$(document).on('click', '.optTranBtnMore span', function(){
$(this).addClass('active').siblings().removeClass('active');
});

$(document).on('click', '.payReqOpt button', function(){
$(this).addClass('active').siblings().removeClass('active');
});
},[]);

useEffect(() => {
if(tranData.length > 0 && userActive.length > 0){
if(trans === 'self'){
if(tranPType === 'all' && tranPSType === 'all'){
setViewTran(tranData.filter((e) => e.sender === userActive[0].customer)); 
}
else if(tranPType !== 'all' && tranPSType === 'all'){
setViewTran(tranData.filter((e) => e.sender === userActive[0].customer && e.paymentType === tranPType)); 
}
else if(tranPType === 'all' && tranPSType !== 'all'){
setViewTran(tranData.filter((e) => e.sender === userActive[0].customer && e.paymentStatus === tranPSType)); 
}
else if(tranPType !== 'all' && tranPSType !== 'all'){
setViewTran(tranData.filter((e) => e.sender === userActive[0].customer && e.paymentType === tranPType && e.paymentStatus === tranPSType)); 
}
}
else{
if(tranPType === 'all' && tranPSType === 'all'){
setViewTran(tranData.filter((e) => e.receiver === userActive[0].customer)); 
}
else if(tranPType !== 'all' && tranPSType === 'all'){
setViewTran(tranData.filter((e) => e.receiver === userActive[0].customer && e.paymentType === tranPType)); 
}
else if(tranPType === 'all' && tranPSType !== 'all'){
setViewTran(tranData.filter((e) => e.receiver === userActive[0].customer && e.paymentStatus === tranPSType)); 
}
else if(tranPType !== 'all' && tranPSType !== 'all'){
setViewTran(tranData.filter((e) => e.receiver === userActive[0].customer && e.paymentType === tranPType && e.paymentStatus === tranPSType)); 
}
}
}
},[tranData, userActive, trans, tranPType, tranPSType]);

return (
<>
<div className='payReqDivWrap'>
<div className='payReqOpt'>
<button className='active' onClick={() => setTrans('self')}>self transation</button>
<button onClick={() => setTrans('user')}>customers transation</button>
</div>

<div className='payReqOptTran'>
<div className='optTranWrap'>
<div className='optTranTitle'>
<span>filter payment type</span>
</div>
<div className='optTranBtn'>
<span className='active' onClick={() => setTranPType('all')}>all</span>
<span onClick={() => setTranPType('upi')}>upi</span>
<span onClick={() => setTranPType('bank')}>bank</span>
</div>
<div className='optTranBtnMore'>
<span className='active' onClick={() => setTranPSType('all')}>all</span>
<span onClick={() => setTranPSType('approved')}>approved</span>
<span onClick={() => setTranPSType('rejected')}>rejected</span>
<span onClick={() => setTranPSType('pending')}>pending</span>
</div>
</div>
</div>

<div className='payReqDiv'>
<div className='stateBody'>
<div className='sort-pageDiv'>
<div className='sortInput'>
<label>sort page :</label> 
<select className='sm-select' onChange={(e) => handleSort(e)}>
<option>25</option>
<option>50</option>
<option>100</option>
<option>250</option>
</select>
</div>

<input type='text' name='search_data' placeholder='search sender...' onChange={(e) => searchUsersTran(e.target.value)} />
</div>

<div className='totalRecords_div'>
<span>display {records.length} records out of total {viewTran.length} records...</span>
</div>

<div className='table_responsiveMx'>
<table className='usersTableLg'>
<thead>
<tr>
<th>transation_no.</th>
<th>receiver</th>
<th>sender</th>
<th>sender_role</th>
<th>payment type</th>
<th>payment method</th>
<th>upi_ac_name</th>
<th>upi_ac_id</th>
<th>Bank_name</th>
<th>holder_name</th>
<th>account_no</th>
<th>ifsc_code</th>
<th> deposit/withdrawal amount</th>
<th>payment_status</th>
<th>payment_date</th>
<th>action</th>
</tr>
</thead>
<tbody>
{
records.length > 0 ? records.map((data, index) => {
return(
<tr key={index}>
<td>{data.tranNum}</td>
<td>{data.receiver}</td>
<td>{data.sender}</td>
<td>{data.sender_role}</td>
<td>{data.paymentType}</td>
<td>{data.paymentMethod}</td>
<td>{data.upiAcName !== 'null' ? data.upiAcName : '--'}</td>
<td>{data.upiAcId !== 'null' ? data.upiAcId : '--'}</td>
<td>{data.bankName !== 'null' ? data.bankName : '--'}</td>
<td>{data.holderName !== 'null' ? data.holderName : '--'}</td>
<td>{data.bankAcNo !== 'null' ? data.bankAcNo : '--'}</td>
<td>{data.bankIfsc !== 'null' ? data.bankIfsc : '--'}</td>
<td>
{
data.paymentMethod === 'deposit' ?
<span className='successTxt'> +{parseFloat(data.depositAmt.toFixed(2)).toLocaleString()} </span>
: 
<span className='dangerTxt'> -{parseFloat(data.depositAmt.toFixed(2)).toLocaleString()} </span>
}
</td>
<td>{
data.paymentStatus === 'approved' ?
<span className='successTxtNor'>{data.paymentStatus}</span>
:
data.paymentStatus === 'rejected' ?
<span className='dangerTxtNor'>{data.paymentStatus}</span>
:
<span className='pendingTxtNor'>{data.paymentStatus}</span>
}</td>
<td>{data.dateTime}</td>
<td>
{
data.path !== null && data.paymentStatus === 'approved' ?
<a href={data.path} target='blank'><button className='docViewBtn'>document</button></a>
: '--'
}
</td>
</tr>
)
})
:
<tr>
<td colSpan='16' className='tdNo'><span className='tdNoRecord'>no records founds...</span></td>
</tr>
}
</tbody>
</table>
</div>

<div className='pagiNation_wrap'>
<div className='paginationDrop'>
<div className='sortInput'>
<label>page no :</label>    
<select onChange={(e) => setCurrPage(e.target.value)}>
{
pageNumber.map((data, index) =>{
return(
<option key={index}>{data}</option>
)
})
}
</select>
</div>
</div>

<ul>
<li className='pagination-btn radiusLeft' onClick={() => prevPage()}>Prev</li>
<li><span className='pagination-items'>{pageNumber.length > 0 ? currPage : 0} of {pageNumber.length}</span></li>
<li className='pagination-btn radiusRight' onClick={() => nextPage()}>Next</li>
</ul>
</div>
</div>
</div>

</div>
</>
)
}

export default Transation;
