import React, { useEffect, useState } from 'react';
import $ from 'jquery';
import axios from 'axios';
import { GlobalContext } from '../../context/AppContext';

const onlTransApi = '/server2/online_transation';

const Withdraw = () => {

const { upi_data, bank_data, tranData, GetTranData, userActive } = GlobalContext();
const [currDepAmt, setCurrDepAmt] = useState(0);
const [ withUpi, setWithUpi] = useState([]);
const [viewWith, setViewWith] = useState(null);
const [withId, setWithId] = useState(null);
const [pendingWith, setPendingWith] = useState([]);

const autoChanAmtDep = (val) => {
if(val <= 0){
setCurrDepAmt(0);
}
else{
setCurrDepAmt(Math.round(val));
}
}

const currDepoMin = () => {
var depoAmt = currDepAmt;
if(depoAmt <= 0){
setCurrDepAmt(0);
}
else{
setCurrDepAmt(parseFloat(depoAmt) - 1);
}
}

const currDepoMax = () => {
var depoAmt = currDepAmt;
setCurrDepAmt(parseFloat(depoAmt) + 1);
}

const setPayStake = (amt) => {
var currAmtState = currDepAmt;
setCurrDepAmt(parseFloat(currAmtState) + parseFloat(amt));
}

const submitWithReq = async() => {
const findData = withUpi.length > 0 && withUpi.filter((e) => parseInt(e.id) === parseInt(withId));
if(findData.length > 0){
var upiAcName = findData[0].upiAcName !== undefined ? findData[0].upiAcName.toLowerCase() : null;
var upiAcId = findData[0].upiAcId !== undefined ? findData[0].upiAcId.toLowerCase() : null;
var bankName = findData[0].bankName !== undefined ? findData[0].bankName.toLowerCase() : null;
var holderName = findData[0].holderName !== undefined ? findData[0].holderName.toLowerCase() : null;
var bankAcNo = findData[0].bankAcNo !== undefined ? findData[0].bankAcNo : null;
var bankIfsc = findData[0].bankIfsc !== undefined ? findData[0].bankIfsc.toLowerCase() : null;
var receiver = userActive.length > 0 ? userActive[0].creator : null;
var sender = userActive.length > 0 ? userActive[0].customer : null;
var sender_role = userActive.length > 0 ? userActive[0].user_role : null;
var depositAmt = currDepAmt;
var paymentStatus = 'pending';
var paymentType = viewWith;
var paymentMethod = 'withdraw';
var userBalC = userActive.length > 0 ? userActive[0].total_balance : 0;

if(depositAmt <= 0){
$('#msgAlert').fadeIn().addClass('danger').removeClass('success').html('withdraw amount is 0');
setTimeout(() => {
$('#msgAlert').hide();
},1000);
return false;
}
if(parseFloat(depositAmt) > parseFloat(userBalC) || userBalC === 0){
$('#msgAlert').fadeIn().addClass('danger').removeClass('success').html('you have no sufficiant balance');
setTimeout(() => {
$('#msgAlert').hide();
},1000);
return false;
}
else{
try {
const sendData = await axios.post('/server2/withdraw_online', {
upiAcName : upiAcName,
upiAcId : upiAcId,
bankName : bankName,
holderName : holderName,
bankAcNo : bankAcNo,
bankIfsc : bankIfsc,
receiver : receiver,
sender : sender,
sender_role : sender_role,
depositAmt : depositAmt,
paymentStatus : paymentStatus,
paymentType : paymentType,
paymentMethod : paymentMethod
});
const resData = await sendData.data;
if(resData === 'success'){
$('#msgAlert').fadeIn().addClass('success').removeClass('danger').html('withdraw request submited successfully');
setTimeout(() => {
$('#msgAlert').hide();
setCurrDepAmt(0);
setWithUpi([]);
GetTranData(onlTransApi);
},1000);
return false;
}
else{
$('#msgAlert').fadeIn().addClass('danger').removeClass('success').html(resData);
setTimeout(() => {
$('#msgAlert').hide();
},1000);  
}
} catch (error) {
console.log('backend withdraw_reqest error : ' + error);
}
}
}
else{
$('#msgAlert').fadeIn().addClass('danger').removeClass('success').html('withdraw banking required');
setTimeout(() => {
$('#msgAlert').hide();
},1000);
return false;
}
}

useEffect(() => {
if(upi_data.length > 0 && userActive.length > 0 && viewWith === 'upi'){
setWithUpi(upi_data.filter((e) => e.customer === userActive[0].customer));
}
else if(bank_data.length > 0 && userActive.length > 0 && viewWith === 'bank'){
setWithUpi(bank_data.filter((e) => e.customer === userActive[0].customer));
}
else{
setWithUpi([]); 
}
},[upi_data, bank_data, userActive, viewWith]);

useEffect(() => {
if(tranData.length > 0 && userActive.length > 0){
setPendingWith(tranData.filter((e) => e.sender === userActive[0].customer && e.paymentStatus === 'pending' && e.paymentMethod === 'withdraw'));
}
},[tranData, userActive]);

useEffect(() => {
if(currDepAmt > 0){
$('#withdraw_typeSel').removeAttr('disabled');
if(withUpi.length > 0){
$('#withdraw_bankSel').removeAttr('disabled');
$('#withdraw_SubBtn').removeClass('disabled').removeAttr('disabled');
}
else{
$('#withdraw_bankSel').attr('disabled', 'disabled');  
$('#withdraw_SubBtn').addClass('disabled').attr('disabled', 'disabled');
}
}
else{
$('#withdraw_typeSel').attr('disabled', 'disabled');
$('#withdraw_bankSel').attr('disabled', 'disabled');
$('#withdraw_SubBtn').addClass('disabled').attr('disabled', 'disabled');
}
},[currDepAmt, withUpi]);

useEffect(() => {
GetTranData(onlTransApi);
// eslint-disable-next-line react-hooks/exhaustive-deps
},[]);

return (
<>
{
pendingWith.length > 0 ?
<div className='pendingPayDiv'>
<div className='pendingContent'>
<img src='/images/withdraw.png' alt='pending' />
<span>waiting for withdraw confirmaition</span>
<span>after few minutes your withdraw amount will be reflected in your upi / bank account</span>
</div>
</div>
:
<div className='paymentContents'>

<div className='depositPayBtnWrap'>
<div className='depositPayBtnHead'>
<span>wallet - withdraw</span>
</div>

<div className='depositPayBtnDiv gridMobDepPay'>
<div className='col'>
<div className='payInputRanDiv'>
<span className='leftMin' onClick={currDepoMin}>-</span>
<input type='number' name='depoAmt' value={currDepAmt} placeholder='enter amount' onChange={(e) => autoChanAmtDep(e.target.value)} />
<span className='plusMax' onClick={currDepoMax}>+</span>
</div>
</div>
<div className='col'>
<div className='depositPayAutBtn'>
<button onClick={() => setPayStake(100)}>100+</button>
<button onClick={() => setPayStake(500)}>500+</button>
<button onClick={() => setPayStake(1000)}>1000+</button>
<button onClick={() => setPayStake(5000)}>5000+</button>
<button onClick={() => setPayStake(10000)}>10000+</button>
<button onClick={() => setPayStake(100000)}>100000+</button>
</div>
</div>
</div>
</div>

<div className='depositPayBtnWrap'>
<div className='depositPayBtnHead'>
<span>withdraw option</span>
</div>

<div className='depositPayBtnDiv gridMobDepPay'>

<div className='withReqForm'>
<div className='headWithReq'>
<span>withdraw request</span>
</div>
<div className='reqForWitData'>
<div className='reqFormWith'>
<label>withdraw amount *</label>
<input type='number' value={currDepAmt} placeholder='Enter Amount...' onChange={(e) => autoChanAmtDep(e.target.value)} />
</div>

<div className='reqFormWith'>
<label>withdraw type *</label>
<select id='withdraw_typeSel' onChange={(e) => setViewWith(e.target.value)} disabled>
<option value='null'>---select withdraw type---</option>
<option value='upi'>upi</option>
<option value='bank'>bank</option>
</select>
</div>

<div className='reqFormWith'>
<label>withdraw banking *</label>
<select id='withdraw_bankSel' onChange={(e) => setWithId(e.target.value)} disabled>
<option value='null'>---select withdraw banking---</option>
{
withUpi.length > 0 && withUpi.map((data, index) => {
return (
<option key={index} value={data.id}>{data.upiAcId !== undefined ? (data.upiType +' - '+ data.upiAcId) : (data.bankName +' -'+ data.bankAcNo)}</option>
)
})
}
</select>
</div>

<div className='reqFormWith reqFormWithbtn'>
<button className='disabled' id='withdraw_SubBtn' onClick={submitWithReq}>submit</button>
</div>
</div>
</div>

</div>
</div>

</div>
}
</>
)
}

export default Withdraw;
