import React, { useEffect, useState } from 'react';
import $ from 'jquery';
import DepoRequest from './DepoRequest';
import WithRequest from './WithRequest';

const PayRequest = () => {

const [payReqOption, setPayReqOption] = useState('deposit_request');

useEffect(() => {
$(document).on('click', '.payReqOpt button', function(){
$(this).addClass('active').siblings().removeClass('active');
});
},[]);
return (
<>
<div className='payReqDivWrap'>
<div className='payReqOpt'>
<button className='active' onClick={() => setPayReqOption('deposit_request')}>deposit request</button>
<button onClick={() => setPayReqOption('withdraw_request')}>withdrawal request</button>
</div>

<div className='payReqDiv'>
{ payReqOption === 'deposit_request' ? <DepoRequest /> : <WithRequest /> }
</div>
</div>
</>
)
}

export default PayRequest;
