import React from 'react';
import $ from 'jquery';
import axios from 'axios';
import { GlobalContext } from '../context/AppContext';

const allUsers_api = '/server2/users-list-all';

const EditComm = () => {

const { userActive, userAll } = GlobalContext();

const closeComm = () => {
$('#commisionModal').hide();
$('.clearComm').val('');
}

const updateComm = async() => {
var id = $('#commId').val();
var editComm = $('#editComm').val();
var editCommPass = $('#editCommPass').val();

if(editComm <= 0){
$('#msgAlert').fadeIn().addClass('danger').removeClass('success').html('new commision required');
setTimeout(function(){
$('#msgAlert').hide();
},1000);
return false;
}
if(editCommPass !== (userActive.length > 0 ? userActive[0].password : null)){
$('#msgAlert').fadeIn().addClass('danger').removeClass('success').html('admin password no match');
setTimeout(function(){
$('#msgAlert').hide();
},1000);
return false;
}
else{
try {
const sendData = await axios.post('/server2/update_commision', {
id: id,
editComm: editComm
});
const resData = await sendData.data;
if(resData === 'success'){
$('#msgAlert').fadeIn().addClass('success').removeClass('danger').html('commision updated successfully');
setTimeout(function(){
$('#msgAlert').hide();
userAll(allUsers_api);
$('#commisionModal').hide();
$('.clearComm').val('');
},1000);
return false;
}
else{
$('#msgAlert').fadeIn().addClass('danger').removeClass('success').html(resData);
setTimeout(function(){
$('#msgAlert').hide();
},1000);
}
} catch (error) {
console.log('backend commision-update-api error : ' + error);
}
}
}

return (
<>
<div className='modalsSm' id='commisionModal'>
<div className='modalsSm_content'>
<div className='modalsSm_header'><span>update commision</span> <i className='fa fa-close right' onClick={() => closeComm()}></i></div>
<div className='modalsSm_body'>

<div className='formDiv'>
<div className='formControls flexControl'>
<div><label>new commision *</label></div>
<div>
<input className='clearComm' type='number' name='editComm' id='editComm' placeholder='new commision...' />
<input className='clearComm' type='hidden' id='commId' />
</div>
</div>
<div className='formControls flexControl'>
<div><label>admin password</label></div>
<div><input className='clearComm' type='password' name='editCommPass' id='editCommPass' placeholder='admin password...' /></div>
</div>
<div className='formControlsSm'>
<button onClick={() => updateComm()}>yes</button>
<button onClick={() => closeComm()}>no</button>
</div>
</div>

</div>
</div>
</div>
</>
)
}

export default EditComm;
