import React from 'react';
import $ from 'jquery';
import axios from 'axios';
import { GlobalContext } from '../context/AppContext';

const upiDataApi = '/server2/upi_data';

const AddUpi = () => {

const { userActive, GetUpiData } = GlobalContext();

const closeAddUpi = () => {
$('#addUpiModal').hide();
$('.paymentInput input').val('');
$('.paymentInput select option').prop('selected', null);
}

const addUpiData = async() => {
var upiAcName = $('#upiAcName').val();
var upiAcId = $('#upiAcId').val();
var upiType = $('#upiType').val();
var depoUpiMin = $('#depoUpiMin').val();
var depoUpiMax = $('#depoUpiMax').val();
var customer = userActive.length > 0 && userActive[0].customer;
var user_role = userActive.length > 0 && userActive[0].user_role;
var creator = userActive.length > 0 && userActive[0].creator;

if(upiAcName === ''){
$('#msgAlert').fadeIn().addClass('danger').removeClass('success').html('UPI account name required');
setTimeout(() => {
$('#msgAlert').hide();
},1000);
return false;
}
if(upiAcId === ''){
$('#msgAlert').fadeIn().addClass('danger').removeClass('success').html('UPI account id required');
setTimeout(() => {
$('#msgAlert').hide();
},1000);
return false;
}
if(upiType === 'null' || upiType === ''){
$('#msgAlert').fadeIn().addClass('danger').removeClass('success').html('UPI account type required');
setTimeout(() => {
$('#msgAlert').hide();
},1000);
return false;
}
if(depoUpiMin <= 0){
$('#msgAlert').fadeIn().addClass('danger').removeClass('success').html('UPI min 1 required');
setTimeout(() => {
$('#msgAlert').hide();
},1000);
return false;
}
if(depoUpiMax <= 0){
$('#msgAlert').fadeIn().addClass('danger').removeClass('success').html('UPI max 1 required');
setTimeout(() => {
$('#msgAlert').hide();
},1000);
return false;
}
if(parseFloat(depoUpiMin) >= parseFloat(depoUpiMax)){
$('#msgAlert').fadeIn().addClass('danger').removeClass('success').html('min lower than max');
setTimeout(() => {
$('#msgAlert').hide();
},1000);
return false;
}
else{
try {
const sendData = await axios.post('/server2/upi_add', {
upiAcName : upiAcName,
upiAcId : upiAcId,
upiType : upiType,
depoUpiMin : depoUpiMin,
depoUpiMax : depoUpiMax,
customer : customer,
user_role : user_role,
creator : creator
});
const resData = await sendData.data;
if(resData === 'success'){
$('#msgAlert').fadeIn().addClass('success').removeClass('danger').html('UPI created successfully');
setTimeout(() => {
$('#msgAlert').hide();
$('#addUpiModal').hide();
$('.paymentInput input').val('');
$('.paymentInput select option').prop('selected', null);
GetUpiData(upiDataApi);
},1000);
return false;  
}
else{
$('#msgAlert').fadeIn().addClass('danger').removeClass('success').html(resData);
setTimeout(() => {
$('#msgAlert').hide();
},1000);
return false;
}
} catch (error) {
console.log('frontend add_upi_details error : ' + error);   
}
}
}

const depoMinChange = (val) => {
if(val <= 0){
$('#depoUpiMin').val(0);
}
}

const depoMaxChange = (val) => {
if(val <= 0){
$('#depoUpiMax').val(1);
}
}

return (
<>
<div className='modalsSm' id='addUpiModal'>
<div className='modalsSm_content'>
<div className='modalsSm_header'><span>add upi details</span> <i className='fa fa-close right' onClick={() => closeAddUpi()}></i></div>
<div className='modalsSm_body'>

<div className='paymentForm'>
<div className='paymentInput'>
<label>upi account name *</label>
<input type='text' name='upiAcName' id='upiAcName' placeholder='upi account name...' />
</div>

<div className='paymentInput'>
<label>upi account id *</label>
<input type='text' name='upiAcId' id='upiAcId' placeholder='example@ybl...' />
</div>

<div className='paymentInput'>
<label>upi account type *</label>
<select id='upiType'>
<option value='null'>--please select payment type--</option>
<option value='phonepay'>phonepay</option>
<option value='gpay'>gpay</option>
<option value='paytm'>paytm</option>
<option value='bhim'>bhim upi</option>
</select>
</div>

<div className='paymentInput'>
<label>deposit min *</label>
<input type='number' name='depoUpiMin' id='depoUpiMin' placeholder='deposit min...' onChange={(e) => depoMinChange(e.target.value)} />
</div>

<div className='paymentInput'>
<label>deposit max *</label>
<input type='number' name='depoUpiMax' id='depoUpiMax' placeholder='deposit max...'  onChange={(e) => depoMaxChange(e.target.value)} />
</div>

<div className='paymentUpiBut'>
<button onClick={addUpiData}>add upi</button>
</div>
</div>

</div>
</div>
</div>
</>
)
}

export default AddUpi;
