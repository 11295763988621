import React, { useEffect } from 'react';
import $ from 'jquery';
import axios from 'axios';
import { GlobalContext } from '../context/AppContext';

const bankDataApi = '/server2/bank_data';

const DelBank = ({delCurrBank, setDelCurrBank}) => {

const { GetBankData } = GlobalContext();

const closeDelBank = () => {
$('#delBankModal').hide();
setDelCurrBank(null);
}

const removeBankConf = async() => {
var id = delCurrBank;
try {
const sendData = await axios.post('/server2/remove_bank', {id: id});
const resData = await sendData.data;
if(resData === 'success'){
$('#msgAlert').fadeIn().addClass('success').removeClass('danger').html('bank removed successfully');
setTimeout(() => {
$('#msgAlert').hide();
GetBankData(bankDataApi);
$('#delBankModal').hide();
setDelCurrBank(null);
},1000);
return false;
}
else{
$('#msgAlert').fadeIn().addClass('danger').removeClass('success').html(resData);
setTimeout(() => {
$('#msgAlert').hide();
},1000);  
}
} catch (error) {
console.log('backend remove_bank error : ' + error);
}
}

useEffect(() => {
if(delCurrBank !== null){
$('#delBankModal').show();
}
},[delCurrBank]);

return (
<>
<div className='modalsSm' id='delBankModal'>
<div className='modalsSm_content'>
<div className='modalsSm_header'><span>remove bank details</span> <i className='fa fa-close right' onClick={() => closeDelBank()}></i></div>
<div className='modalsSm_body'>

<div className='formDiv formDivRemove'>
<img src='/images/bin.png' alt='bin' />
<span>are you sure? you want to remove this bank details?</span>
<button onClick={() => removeBankConf()}>yes</button>
<button onClick={() => closeDelBank()}>no</button>
</div>

</div>
</div>
</div>
</>
)
}

export default DelBank;
