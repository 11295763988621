import React, { useEffect, useState } from 'react';
import $ from 'jquery';
import { GlobalContext } from '../../context/AppContext';

const PassHistory = () => {

const { userActive, userList, passHis } = GlobalContext();
const [currPassHis, setCurrPassHis] = useState([]);
const [searchData, setSearchData] = useState([]);
const [currPage, setCurrPage] = useState(1);
const [sortPage, setSortPage] = useState(25);
const recordPage = sortPage;
const lastIndex = currPage * recordPage;
const firstIndex = lastIndex - recordPage;
const records = currPassHis.slice(firstIndex, lastIndex);
const pageLength = Math.ceil(currPassHis.length / recordPage);
const pageNumber = [...Array(pageLength + 1).keys()].slice(1);

const prevPage = () =>{
if(parseInt(currPage) !== 1){
setCurrPage(parseInt(currPage) - 1);
}
}

const nextPage = () =>{
if(parseInt(currPage) !== parseInt(pageLength)){
setCurrPage(parseInt(currPage) + 1);
}
}

const handleSort = (e) =>{
setSortPage(e.target.value);
}

const searchUsers = (name) => {
setCurrPassHis(searchData.filter((e) => e.customer.includes(name)));
}

useEffect(() => {
if(passHis.length > 0 && userActive.length > 0){
var a = passHis.filter((e) => e.changer === userActive[0].customer);
var b = passHis.filter((e) => userList.find((f) => e.customer === f.customer && e.changer !== f.creator));
var c = a.concat(b);
setCurrPassHis(c.slice().sort((a, b) => b.id - a.id));
setSearchData(c.slice().sort((a, b) => b.id - a.id));
}
else{
setCurrPassHis([]);
setSearchData([]);
}

},[userActive, userList, passHis]);

useEffect(() => {
if(records.length <= 0){
$('.usersTable').css({width: '100%'});
}
else{
if(window.screen.width <= 768){
$('.usersTable').css({width: 'max-content'});   
}
}
},[records]);

return (
<>
<div className='content-body'>
<div className='containers'>
<div className='rowWrapFull'>

<div className='profilePage'>
<div className='headerProfile'><span>password change history</span></div>

<div className='stateBody'>
<div className='sort-pageDiv'>
<div className='sortInput'>
<label>sort page :</label> 
<select className='sm-select' onChange={(e) => handleSort(e)}>
<option>25</option>
<option>50</option>
<option>100</option>
<option>250</option>
</select>
</div>

<input type='text' name='search_data' placeholder='search users...' onChange={(e) => searchUsers(e.target.value)} />
</div>

<div className='totalRecords_div'>
<span>display {records.length} records out of total {currPassHis.length} records...</span>
</div>

<div className='table-responsive'>
<table className='usersTable'>
<thead>
<tr>
<th>username</th>
<th>changer</th>
<th>remark</th>
<th>date time</th>
</tr>
</thead>
<tbody>
{
records.length > 0 ? records.map((data, index) => {
return(
<tr key={index}>
<td>{data.customer}</td>
<td>{data.changer}</td>
<td>{data.remark}</td>
<td>{data.create_date}</td>
</tr>
)
})
:
<tr>
<td colSpan='4' className='tdNo'><span className='tdNoRecord'>no records founds...</span></td>
</tr>
}
</tbody>
</table>
</div>

<div className='pagiNation_wrap'>
<div className='paginationDrop'>
<div className='sortInput'>
<label>page no :</label>    
<select onChange={(e) => setCurrPage(e.target.value)}>
{
pageNumber.map((data, index) =>{
return(
<option key={index}>{data}</option>
)
})
}
</select>
</div>
</div>

<ul>
<li className='pagination-btn radiusLeft' onClick={() => prevPage()}>Prev</li>
<li><span className='pagination-items'>{pageNumber.length > 0 ? currPage : 0} of {pageNumber.length}</span></li>
<li className='pagination-btn radiusRight' onClick={() => nextPage()}>Next</li>
</ul>
</div>
</div>
</div>

</div>
</div>
</div>
</>
)
}

export default PassHistory;
