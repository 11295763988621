import React, { useEffect, useState } from 'react';
import $ from 'jquery';
import { useLocation } from 'react-router-dom';
import Upi from './Upi';
import Bank from './Bank';
import Deposit from './Deposit';
import PayRequest from './PayRequest';
import PaySetting from './settings/PaySetting';
import Withdraw from './Withdraw';
import Transation from './transation/Transation';
import { GlobalContext } from '../../context/AppContext';

const Payment = () => {
    
const location = useLocation();
const { userActive } = GlobalContext();
const [paymentPage, setPaymentPage] = useState('upi');

useEffect(() => {
$(document).on('click', '.responBtn button', function(){
$(this).addClass('active').siblings().removeClass('active');
});

if(location.state !== null){
$('.responBtn button').removeClass('active');
setPaymentPage(location.state.pageReq);
if(location.state.pageReq === 'deposit'){
$('.depoPage').addClass('active');
}
else if(location.state.pageReq === 'pay_req'){
$('.reqPg').addClass('active');
}
else if(location.state.pageReq === 'withdraw'){
$('.withPg').addClass('active');
}
else if(location.state.pageReq === 'upi'){
$('.bankPg').addClass('active');
}
}

},[location]);

return (
<>
<div className='content-body'>
<div className='containers'>

<div className='paymentPage'>
<div className='paymentContWrap'>
<div className='paymentOptDiv'>
<div className='responiveDiv'>
<div className='responBtn'>
<button className='active bankPg' onClick={() => setPaymentPage('upi')}>upi setting</button>
<button onClick={() => setPaymentPage('bank')}>bank setting</button>
{ 
userActive.length > 0 && userActive[0].user_role !== 'admin_role' && userActive[0].user_role !== 'customer_role' && userActive[0].user_role !== 'demo_role' &&
<button className='depoPage' onClick={() => setPaymentPage('deposit')}>deposit</button>
}
{ 
userActive.length > 0 && userActive[0].user_role !== 'admin_role' && userActive[0].user_role !== 'customer_role' && userActive[0].user_role !== 'demo_role' &&
<button className='withPg' onClick={() => setPaymentPage('withdraw')}>withdrawal</button>
}
<button className='reqPg' onClick={() => setPaymentPage('pay_req')}>payment request</button>
<button onClick={() => setPaymentPage('transation')}>transation report</button>
<button onClick={() => setPaymentPage('pay_setting')}>payment setting</button>
</div>
</div>
</div>

<div className='paymentBody'>
{
paymentPage === 'upi' ? <Upi /> : paymentPage === 'bank'
? <Bank /> : paymentPage === 'deposit' ? <Deposit /> :
 paymentPage === 'pay_req' ? <PayRequest /> : paymentPage === 'pay_setting' ? 
 <PaySetting /> : paymentPage === 'withdraw' ? <Withdraw /> : <Transation />
}
</div>

</div>
</div>

</div>
</div>
</>
)
}

export default Payment;
