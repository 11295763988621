import React, { useEffect, useState } from 'react';
import QRCode from "react-qr-code";
import $ from 'jquery';
import axios from 'axios';
import { GlobalContext } from '../../context/AppContext';

const onlTransApi = '/server2/online_transation';
const userActive_api = '/server2/active-user';

const WithRequest = () => {

const { tranData, userActive, GetTranData, activeUser } = GlobalContext();
const [withPayReq, setWithPayReq] = useState([]);

const closePreviewImgWith = (id) => {
$('#previewImgWrap_'+id).hide();
$('#previewImg_'+id).removeAttr('src');
$('#qrHolderFile_'+id).show();
$('#withSS_'+id).val('');
}

const setActiveWithImg = (file,id) => {
$('#qrHolderFile_'+id).hide();
$('#previewImgWrap_'+id).show();
$('#previewImg_'+id).attr('src', URL.createObjectURL(file));
}

const viewQrTog = (id) => {
$('#qrDiv_'+id).toggle();
}

const approveWith = async(id,type) => {
var formData = new FormData();
const findCurrReq = withPayReq.length > 0 && withPayReq.filter((e) => e.id === id);
var fileImg = $('#withSS_'+id)[0].files[0];
var tranId = id;
var tranNum = $('#tranNum_'+id).val();
var receiver = findCurrReq.length > 0 && findCurrReq[0].receiver;
var sender = findCurrReq.length > 0 && findCurrReq[0].sender;
var depositAmt = findCurrReq.length > 0 && findCurrReq[0].depositAmt;
var depositType = type;

if(fileImg === undefined && depositType === 'approve'){
$('#msgAlert').fadeIn().addClass('danger').removeClass('success').html('transation screenshot required');
setTimeout(() => {
$('#msgAlert').hide();
},1000);
return false;
}
if(tranNum === ''){
$('#msgAlert').fadeIn().addClass('danger').removeClass('success').html('transation number required');
setTimeout(() => {
$('#msgAlert').hide();
},1000);
return false;
}
else{
$('#payReqLoad_'+id).css({display: 'flex'});
formData.append('upiImg', fileImg);
formData.append('tranId', tranId);
formData.append('tranNum', tranNum);
formData.append('receiver', receiver);
formData.append('sender', sender);
formData.append('depositAmt', depositAmt);
formData.append('depositType', depositType);

try {
const sendData = await axios.post('/server2/withdraw_online_clear', formData, {
headers : {'Content-Type' : 'multipart/form-data'}
});
const resData = await sendData.data;
if(resData === 'success'){
$('#msgAlert').fadeIn().addClass('success').removeClass('danger').html('withdraw successfully cleared');
setTimeout(() => {
$('#msgAlert').hide();
$('#payReqLoad_'+id).hide();
$('#tranNum_'+id).val('');
$('#previewImgWrap_'+id).hide();
$('#previewImg_'+id).removeAttr('src');
$('#withSS_'+id).val('');
$('#qrHolderFile_'+id).show();
GetTranData(onlTransApi);
activeUser(userActive_api);
},1000);
return false;
}
else{
$('#msgAlert').fadeIn().addClass('danger').removeClass('success').html(resData);
setTimeout(() => {
$('#msgAlert').hide();
$('#payReqLoad_'+id).hide();
$('#tranNum_'+id).val('');
},1000);  
}
} catch (error) {
console.log('backend withdraw_online error : ' + error);
}
}
}

useEffect(() => {
if(tranData.length > 0 && userActive.length > 0){
setWithPayReq(tranData.filter((e) => e.receiver === userActive[0].customer && e.paymentStatus === 'pending' && e.paymentMethod === 'withdraw'));
}
},[tranData, userActive]);

return (
<>
{
withPayReq.length > 0 ? withPayReq.map((data, index) => {
return (
<div className='payReqRowWrap' key={index}>

<div className='payReqLoad' id={'payReqLoad_'+data.id}>
<div>
<img src='/images/paper-plane.png' alt='paper-plane' />
<span>withdraw request sending</span>
</div>
</div>

<div className='paymentTitle'>
<span>{data.paymentType} withdraw by {data.sender}</span>
</div>

<div className='payReqRow'>

<div className='col'>
{
data.paymentType === 'upi' &&
<div className='titleOpt'>
<span>scan qr code with any upi app and pay the amount</span>
</div>
}

{
data.paymentType === 'upi' &&
<div className='qrWraps'>
<button className='viewQrBtn' onClick={() => viewQrTog(data.id)}>view qr code</button>

<div className='qrDiv qrDivwith' id={'qrDiv_'+data.id}>
<QRCode 
size={256}
style={{ height: "auto", maxWidth: "100%", width: "100%" }}
value={`upi://pay?pa=${data.upiAcId}&pn=${data.upiAcName}&tn=cftrhwetaw4gta&am=1000`}
viewBox={`0 0 256 256`}
/>
<div className='footQr'>
<span>{data.upiAcName}</span>
</div>
</div>
</div>
}

<div className='titleOpt topMargin15'>
<span>upload withdrawal transation proof</span>
</div>

<div className='qrHolderFile' id={'qrHolderFile_'+data.id}>
<label><i className='fa fa-plus' />
<input type='file' name='withSS' id={'withSS_'+data.id} accept='image/*' onChange={(e) => setActiveWithImg(e.target.files[0],data.id)} />
click here to upload payment screenshot</label>
</div>

<div className='previewImgWrap previewImgWrapWith' id={'previewImgWrap_'+data.id}>
<i className='fa fa-close' onClick={() => closePreviewImgWith(data.id)} />
<div className='previewImg'>
<img id={'previewImg_'+data.id} alt='previewImg' />
</div>
</div>

</div>

<div className='col'>
{
data.paymentType === 'upi' &&
<div className='payReqData'>
<div className='col_1'>
<span>upi account name :</span>
</div>
<div className='col_1'>
<span>{data.upiAcName}</span>
</div>
</div>
}

{
data.paymentType === 'upi' &&
<div className='payReqData'>
<div className='col_1'>
<span>upi account id :</span>
</div>
<div className='col_1'>
<span>{data.upiAcId}</span>
</div>
</div>
}

{
data.paymentType === 'bank' &&
<div className='payReqData'>
<div className='col_1'>
<span>bank name :</span>
</div>
<div className='col_1'>
<span>{data.bankName}</span>
</div>
</div>
}

{
data.paymentType === 'bank' &&
<div className='payReqData'>
<div className='col_1'>
<span>holder name :</span>
</div>
<div className='col_1'>
<span>{data.holderName}</span>
</div>
</div>
}

{
data.paymentType === 'bank' &&
<div className='payReqData'>
<div className='col_1'>
<span>account no. :</span>
</div>
<div className='col_1'>
<span>{data.bankAcNo}</span>
</div>
</div>
}

{
data.paymentType === 'bank' &&
<div className='payReqData'>
<div className='col_1'>
<span>ifsc code :</span>
</div>
<div className='col_1'>
<span>{data.bankIfsc}</span>
</div>
</div>
}

<div className='payReqData'>
<div className='col_1'>
<span>sender :</span>
</div>
<div className='col_1'>
<span>{data.sender}</span>
</div>
</div>

<div className='payReqData'>
<div className='col_1'>
<span>sender role :</span>
</div>
<div className='col_1'>
<span>{data.sender_role}</span>
</div>
</div>

<div className='payReqData'>
<div className='col_1'>
<span>withdrawal amount :</span>
</div>
<div className='col_1'>
<span>{data.depositAmt}</span>
</div>
</div>

<div className='payReqData'>
<div className='col_1'>
<span>withdrawal type :</span>
</div>
<div className='col_1'>
<span>{data.paymentType}</span>
</div>
</div>

<div className='payReqData'>
<div className='col_1'>
<span>withdrawal status :</span>
</div>
<div className='col_1'>
<span>{data.paymentStatus}</span>
</div>
</div>

<div className='payReqData'>
<div className='col_1'>
<span>withdrawal date :</span>
</div>
<div className='col_1'>
<span>{data.dateTime}</span>
</div>
</div>

<div className='payReqData'>
<div className='col_1'>
<span>enter transation detail :</span>
</div>
<div className='col_1'>
<input type='text' className='tranNum' id={'tranNum_'+data.id} placeholder='enter transation number' />
</div>
</div>

<div className='payReqData payReqDataBut'>
<div className='col_1'>
<button onClick={() => approveWith(data.id,'approve')}>approve</button>
</div>
<div className='col_1'>
<button onClick={() => approveWith(data.id,'reject')}>reject</button>
</div>
</div>

</div>
</div>
</div>
)
})
:
<div className='noRecDiv'>
<div className='gridDiv gridDivNoRec'>
<img src='/images/card.png' alt='card' />
<span>you have no any withdrawal request</span>
</div>
</div>
}
</>
)
}

export default WithRequest;
