import React, { useEffect, useState } from 'react';
import $ from 'jquery';
import { useLocation, useNavigate } from 'react-router-dom';
import { GlobalContext } from '../../context/AppContext';
import TotalBal from '../TotalBal';
import StatusAlert from '../../pages/StatusAlert';

const Users = () => {

const { allUsers, useExpo, userActive } = GlobalContext();
const [isChecked, setIsChecked] = useState(false);
const Location = useLocation();
const [currUsers, setCurrUsers] = useState([]);
const [currPage, setCurrPage] = useState(1);
const [sortPage, setSortPage] = useState(25);
const recordPage = sortPage;
const lastIndex = currPage * recordPage;
const firstIndex = lastIndex - recordPage;
const records = currUsers.slice(firstIndex, lastIndex);
const pageLength = Math.ceil(currUsers.length / recordPage);
const pageNumber = [...Array(pageLength + 1).keys()].slice(1);
const Navigate = useNavigate();

const prevPage = () =>{
if(parseInt(currPage) !== 1){
setCurrPage(parseInt(currPage) - 1);
}
}
    
const nextPage = () =>{
if(parseInt(currPage) !== parseInt(pageLength)){
setCurrPage(parseInt(currPage) + 1);
}
}
    
const handleSort = (e) =>{
setSortPage(e.target.value);
}

const chipsSummary = () => {
setIsChecked(!isChecked); 
localStorage.setItem('chipsKey', JSON.stringify(isChecked));
}

const userProfitLoss = (user,userRole) => {
Navigate('/profit_loss', {state:{name:user, role:userRole}});
}

const userBetHistory = (user,userRole) => {
Navigate('/bet_history', {state:{name:user, role:userRole}});
}

const userProfile = (user,userRole) => {
Navigate('/user_profile', {state:{name:user, role:userRole}});
}

const findSuper = (usr, role) => {
Navigate('/all-admin/users', {state:{name:usr, role:role}}); 
}

const searchUsers = (name) => {
setCurrUsers(allUsers.filter((e) =>  e.creator === Location.state.name && e.customer.includes(name)));
}

useEffect(() => {
if(allUsers.length > 0){
if(Location.state !== null){
setCurrUsers(allUsers.filter((e) => e.creator === Location.state.name));
}
}
},[allUsers, Location.state]);

useEffect(() => {
currUsers.forEach((e) => {
let a = 0;
let b = 0;
let c = 0;

if(e.user_role === 'agent_role'){
c = allUsers.length > 0 && allUsers.filter((f) => f.agent === e.customer).reduce((g, h) => {
g = g + h.total_balance;
return g;
}, 0);
}
else if(e.user_role === 'super_role'){
c = allUsers.length > 0 && allUsers.filter((f) => f.super_master === e.customer).reduce((g, h) => {
g = g + h.total_balance;
return g;
}, 0);
}
else if(e.user_role === 'master_role'){
c = allUsers.length > 0 && allUsers.filter((f) => f.master === e.customer).reduce((g, h) => {
g = g + h.total_balance;
return g;
}, 0);
}

if(c !== false){
if(e.creditRefrence > (e.total_balance + c)){
a = (e.total_balance + c) - e.creditRefrence;
}else{
a = (e.total_balance + c) - e.creditRefrence;
}

if(isChecked){
b = (a * e.partnership) / 100;
if(b > 0){
$('#ChipsFund_'+e.id).addClass('successTxt').text('('+parseFloat(b.toFixed(2)).toLocaleString()+')');
}
else{
$('#ChipsFund_'+e.id).addClass('dangerTxt').text('('+parseFloat(b.toFixed(2)).toLocaleString()+')');  
}
}
else{
if(a > 0){
$('#ChipsFund_'+e.id).addClass('successTxt').removeClass('dangerTxt').text('('+parseFloat(a.toFixed(2)).toLocaleString()+')');
}else{
$('#ChipsFund_'+e.id).addClass('dangerTxt').removeClass('successTxt').text('('+parseFloat(a.toFixed(2)).toLocaleString()+')');  
} 
}
}

});
},[currUsers, allUsers, isChecked]);

useEffect(() => {
let currChips = JSON.parse(localStorage.getItem('chipsKey'));
if(currChips === false){
setIsChecked(true); 
}else{
setIsChecked(false);    
}
},[]);

return (
<>
<div className='content-body'>
<div className='containers'>
<StatusAlert />
<div className='firstColDiv'>
<div className='content'>


<div className='usersRoleDiv'>
{
Location.state !== null &&
<div className='coverRole'>
<span className='roleTxt'>{Location.state.role.split('_')[0]}</span>
<span>{Location.state.name}</span>
</div>
}

{
userActive.length > 0 &&
<div className='coverRole'>
<span className='roleTxt'>
{
userActive[0].user_role === 'admin_role' ? 'white label' : 
userActive[0].user_role === 'subadmin_role' ? 'subadmin' :
userActive[0].user_role === 'super_role' ? 'super' : 
userActive[0].user_role === 'master_role' ? 'master' : null
}
</span>
<span>{userActive[0].customer}</span>
</div>
}
</div>

<div className='chipsSwitch'>
<span>chips summary</span>
<label className='chipsSummary'>
<input type='checkbox' checked={isChecked} onChange={chipsSummary} />
<span className='chipsSlider'></span>
</label>
</div>
</div>
</div>

<TotalBal check={isChecked} />

<div className='firstColDiv tableWrap'>

<div className='sort-pageDiv'>
<div className='sortInput'>
<label>sort page :</label> 
<select className='sm-select' onChange={(e) => handleSort(e)}>
<option>25</option>
<option>50</option>
<option>100</option>
<option>250</option>
</select>
</div>

<input type='text' name='search_data' placeholder='search users...' onChange={(e) => searchUsers(e.target.value)} />
</div>

<div className='totalRecords_div'>
<span>display {records.length} records out of total {currUsers.length} records...</span>
</div>

<div className='table-responsive'>
<table className='usersTable'>
<thead>
<tr>
<th>username</th>
<th>currency</th>
<th>credit ref.</th>
<th>partnership</th>
<th>balance</th>
<th>exposure</th>
<th>avail. bal.</th>
<th>ref. p/l</th>
<th>status</th>
<th>action</th>
</tr>
</thead>
<tbody>
{
records.length > 0 ? records.map((data, index) => {
var currStatus = null;
let currExpo = 0;
let subAdmUsrBal = 0;
if(data.status === 'active'){
currStatus = <span className='statusTd actGreen'>active</span>;   
}
else if(data.status === 'suspended'){
currStatus = <span className='statusTd actRed'>suspended</span>;   
}
else{
currStatus = <span className='statusTd actYell'>locked</span>;   
}

if(data.user_role === 'agent_role'){
currExpo = useExpo.length > 0 && useExpo.filter((e) => e.agent === data.customer).reduce((k, l) => {
k = k + l.exposure;
return k;
},0);

subAdmUsrBal = allUsers.length > 0 && allUsers.filter((e) => e.agent === data.customer).reduce((c, d) => {
c = c + d.total_balance;
return c;
},0);
}
else if(data.user_role === 'super_role'){
currExpo = useExpo.length > 0 && useExpo.filter((e) => e.super_master === data.customer).reduce((k, l) => {
k = k + l.exposure;
return k;
},0);

subAdmUsrBal = allUsers.length > 0 && allUsers.filter((e) => e.super_master === data.customer).reduce((c, d) => {
c = c + d.total_balance;
return c;
},0);
}
else if(data.user_role === 'master_role'){
currExpo = useExpo.length > 0 && useExpo.filter((e) => e.master === data.customer).reduce((k, l) => {
k = k + l.exposure;
return k;
},0);

subAdmUsrBal = allUsers.length > 0 && allUsers.filter((e) => e.master === data.customer).reduce((c, d) => {
c = c + d.total_balance;
return c;
},0);
}
else if(data.user_role === 'customer_role'){
currExpo = useExpo.length > 0 && useExpo.filter((e) => e.customer === data.customer).reduce((k, l) => {
k = k + l.exposure;
return k;
},0);

}

//console.log(subAdmUsrBal);

return(
<tr key={index}>
<td>
<div className='flex_td'>
{
data.coming === 'online' ? 
<span className='onlineAct'></span>
:
<span className='offlineAct'></span>
}

<span className='roleTxt'>{data.user_role.slice(0, 3)}</span> {data.user_role !== 'customer_role' ? <span className='links' onClick={() => findSuper(data.customer,data.user_role)}>{data.customer}</span> : <span>{data.customer}</span>}
</div>
</td>
<td>{data.currency}</td>
<td>{parseFloat(data.creditRefrence.toFixed(2)).toLocaleString()}</td>
<td>{data.partnership}</td>
<td>{parseFloat((data.total_balance + subAdmUsrBal).toFixed(2)).toLocaleString()}</td>
<td><span className='dangerTxt'>({parseFloat((currExpo !== false ? currExpo : 0).toFixed(2)).toLocaleString()})</span></td>
<td>
{
data.user_role === 'customer_role' ?
parseFloat((data.total_balance - currExpo).toFixed(2)).toLocaleString() :
parseFloat(data.total_balance.toFixed(2)).toLocaleString()
}
</td>
<td className='ChipsFund' id={'ChipsFund_'+data.id}>{parseFloat(data.creditRefrence) > parseFloat(data.total_balance + subAdmUsrBal) ? <span className='dangerTxt'>({parseFloat((parseFloat(data.total_balance + subAdmUsrBal) - parseFloat(data.creditRefrence)).toFixed(2)).toLocaleString()})</span> : <span className='successTxt'>({parseFloat((parseFloat(data.total_balance + subAdmUsrBal) - parseFloat(data.creditRefrence)).toFixed(2)).toLocaleString()})</span>}</td>
<td>{currStatus}</td>
<td>
{
data.user_role === 'customer_role' &&
<span>
<button onClick={() => userProfitLoss(data.customer,data.user_role)} className='tdBtn'><img src='/images/upDown.png' alt='upDown' /></button>
<button onClick={() => userBetHistory(data.customer,data.user_role)} className='tdBtn'><img src='/images/history.png' alt='history' /></button>
</span>
}
<button onClick={() => userProfile(data.customer,data.user_role)} className='tdBtn'><img src='/images/user.png' alt='bank-building' /></button>
</td>
</tr>
)
})
:
<tr>
<td colSpan='9' className='tdNo'><span className='tdNoRecord'>no records founds...</span></td>
</tr>
}
</tbody>
</table>
</div>

<div className='pagiNation_wrap'>
<div className='paginationDrop'>
<div className='sortInput'>
<label>page no :</label>    
<select onChange={(e) => setCurrPage(e.target.value)}>
{
pageNumber.map((data, index) =>{
return(
<option key={index}>{data}</option>
)
})
}
</select>
</div>
</div>

<ul>
<li className='pagination-btn radiusLeft' onClick={() => prevPage()}>Prev</li>
<li><span className='pagination-items'>{pageNumber.length > 0 ? currPage : 0} of {pageNumber.length}</span></li>
<li className='pagination-btn radiusRight' onClick={() => nextPage()}>Next</li>
</ul>
</div>

</div>

</div>
</div>
</>
)
}

export default Users;
