import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import CanvasJSReact from '@canvasjs/react-charts';
import axios from 'axios';
import StatusAlert from './StatusAlert';
import { countDataT } from './checkReq';
import { GlobalContext } from '../context/AppContext';

var CanvasJSChart = CanvasJSReact.CanvasJSChart;

const Home = () => {

    const { userActive } = GlobalContext();
const [currRepo, setCurrRepo] = useState([]);
const [liveRepo, setLiveRepo] = useState([]);
const [liveOpt, setLiveOpt] = useState([]);
const [backRepo, setBackRepo] = useState([]);
const [backOpt, setBackOpt] = useState([]);
const Navigate = useNavigate();

const openDepoPage = () => {
Navigate('/payment_setting', {state:{pageReq : 'deposit'}});
}

const openPayRePage = () => {
Navigate('/payment_setting', {state:{pageReq : 'pay_req'}});   
}

const openWithPage = () => {
Navigate('/payment_setting', {state:{pageReq : 'withdraw'}});    
}

const openAdBnPage = () => {
Navigate('/payment_setting', {state:{pageReq : 'upi'}});    
}

const options = {
animationEnabled: true,
exportEnabled: false,
theme: "light1", // "light1", "dark1", "dark2"
/*
title:{
text: "Trip Expenses"
},
*/
data: [{
type: "pie",
indexLabel: "{label}: {y} ₹",		
startAngle: -90,
dataPoints: liveOpt
}]
}

const options2 = {
animationEnabled: true,
exportEnabled: false,
theme: "light1", // "light1", "dark1", "dark2"
/*
title:{
text: "Trip Expenses"
},
*/
data: [{
type: "pie",
indexLabel: "{label}: {y} ₹",		
startAngle: -90,
dataPoints: backOpt
}]
}

for(let i=0; i < options.data[0].dataPoints.length; i++){
if(options.data[0].dataPoints[i].y >= 0){
options.data[0].dataPoints[i].indexLabelFontColor = '#009933';
options.data[0].dataPoints[i].labelFontWeight = 'bold';
}
else{
options.data[0].dataPoints[i].indexLabelFontColor = '#e60000';  
options.data[0].dataPoints[i].labelFontWeight = 'bold'; 
}
}

for(let i=0; i < options2.data[0].dataPoints.length; i++){
if(options2.data[0].dataPoints[i].y >= 0){
options2.data[0].dataPoints[i].indexLabelFontColor = '#009933';
options2.data[0].dataPoints[i].labelFontWeight = 'bold';
}
else{
options2.data[0].dataPoints[i].indexLabelFontColor = '#e60000';  
options2.data[0].dataPoints[i].labelFontWeight = 'bold'; 
}
}

useEffect(() => {
async function fetchData(){
try {
const sendData = await axios.get('/server2/settle_betsAll');
const resData = await sendData.data;
if(resData.length > 0){
setCurrRepo(resData);
}
else{
setCurrRepo([]);
}
} catch (error) {
console.log('backend home-settle-bet-api error : ' + error);
}
}

fetchData();
},[]);

useEffect(() => {
if(currRepo.length > 0){
var findDate = new Date().toDateString();
setLiveRepo(currRepo.filter((e) => e.bet_date >= Date.parse(findDate)).reduce((a, b) => {
const { sport, win_status, profit, loss, eventName } = b;
var currSports = '';
var item = '';
if(eventName === 'casino' || eventName === 'virtual casino'){
currSports = eventName;
item = a.find((f) => f.currSports === currSports);
}
else{
currSports = sport;
item = a.find((f) => f.currSports === currSports); 
}

let prf = 0;
let los = 0;
let pls = 0;

if(win_status === 'win'){
prf = prf + profit;
}
else{
los = los + loss;
}

pls = parseFloat(prf) - parseFloat(los);
item ? item.pls += pls : a.push({currSports,pls});
return a;
},[]));

setBackRepo(currRepo.filter((e) => e.bet_date < Date.parse(findDate)).reduce((a, b) => {
const { sport, win_status, profit, loss, eventName } = b;
var currSports = '';
var item = '';
if(eventName === 'casino' || eventName === 'virtual casino'){
currSports = eventName;
item = a.find((f) => f.currSports === currSports);
}
else{
currSports = sport;
item = a.find((f) => f.currSports === currSports); 
}

let prf = 0;
let los = 0;
let pls = 0;

if(win_status === 'win'){
prf = prf + profit;
}
else{
los = los + loss;
}

pls = parseFloat(prf) - parseFloat(los);
item ? item.pls += pls : a.push({currSports,pls});
return a;
},[]));
}
},[currRepo]);

useEffect(() => {
var allLive = [];
if(liveRepo.length > 0){
liveRepo.forEach((e) => {
var data = {
y: e.pls === 0 ? 1 : e.pls,
label: e.currSports
}

const findKey = allLive.findIndex((O) => O.label === e.currSports);
if(findKey === -1){
allLive.push(data);
}
});
}
else{
allLive.push({y: 1, label: 'cricket'});
}
setLiveOpt(allLive);
},[liveRepo]);

useEffect(() => {
var allLive = [];
if(backRepo.length > 0){
backRepo.forEach((e) => {
var data = {
y: e.pls === 0 ? 1 : e.pls,
label: e.currSports
}

const findKey = allLive.findIndex((O) => O.label === e.currSports);
if(findKey === -1){
allLive.push(data);
}
});
}
else{
allLive.push({y: 1, label: 'cricket'});
}
setBackOpt(allLive);
},[backRepo]);

return (
<>
<div className='content-body'>
<div className='containers'>
<StatusAlert />

<div className='paymentHome'>
<div className='rowPH'>
<div className='col'>
<div className='conte' onClick={openPayRePage}>
<img src='/images/cash-payment.png' alt='pay_img' />
<span>payment request</span>
<span className='payReqNot'>{countDataT}</span>
</div>
</div>

{
userActive.length > 0 && userActive[0].user_role !== 'admin_role' &&
<div className='col'>
<div className='conte' onClick={openDepoPage}>
<img src='/images/wallet.png' alt='pay_img' />
<span>deposit</span>
</div>
</div>
}

{
userActive.length > 0 && userActive[0].user_role !== 'admin_role' &&
<div className='col'>
<div className='conte' onClick={openWithPage}>
<img src='/images/withdrawal.png' alt='pay_img' />
<span>withdraw</span>
</div>
</div>
}

<div className='col'>
<div className='conte' onClick={openAdBnPage}>
<img src='/images/banking.png' alt='pay_img' />
<span>add bank</span>
</div>
</div>
</div>
</div>

<div className='rowWrap2'>
<div className='col'>
<div className='headTitle'><span>live sport profit/loss</span></div>
<CanvasJSChart options={options} />
</div>
<div className='col'>
<div className='headTitle'><span>backup sport profit/loss</span></div>
<CanvasJSChart options={options2} />
</div>
</div>

</div>
</div>
</>
)
}

export default Home;
