import React, { useEffect } from 'react';
import $ from 'jquery';
import axios from 'axios';
import { GlobalContext } from '../../../context/AppContext';

const withDrawLimitApi = '/server2/withdrawLimit_data';

const BankWLimit = () => {

const { userActive, withLimit, GetWithLimitData } = GlobalContext();

const updateLimitWithdrawB = async(role,type) => {
var user_role = role;
var creator = userActive.length > 0 && userActive[0].customer;
let minWithdraw = 0;
let maxWithdraw = 0;
let dayLimit = 1;
var limitType = type;

if(user_role === 'subadmin_role'){
minWithdraw = $('#withDraw_min1B').val();
maxWithdraw = $('#withDraw_max1B').val();
}
else if(user_role === 'super_role'){
minWithdraw = $('#withDraw_min2B').val();
maxWithdraw = $('#withDraw_max2B').val();
}
else if(user_role === 'master_role'){
minWithdraw = $('#withDraw_min3B').val();
maxWithdraw = $('#withDraw_max3B').val();
}
else if(user_role === 'agent_role'){
minWithdraw = $('#withDraw_min4B').val();
maxWithdraw = $('#withDraw_max4B').val();
}
else if(user_role === 'customer_role'){
minWithdraw = $('#withDraw_min5B').val();
maxWithdraw = $('#withDraw_max5B').val();
}

if(minWithdraw <= 0){
$('#msgAlert').fadeIn().addClass('danger').removeClass('success').html('min. withdraw 1');
setTimeout(() => {
$('#msgAlert').hide();
},1000);
return false;
}
if(maxWithdraw < 2){
$('#msgAlert').fadeIn().addClass('danger').removeClass('success').html('max. withdraw min. 2');
setTimeout(() => {
$('#msgAlert').hide();
},1000);
return false;
}
else{
try {
const sendData = await axios.post('/server2/set_withdrawLimit', {
creator : creator,
user_role : user_role,
minWithdraw : minWithdraw,
maxWithdraw : maxWithdraw,
dayLimit : dayLimit,
limitType : limitType
});
const resData = await sendData.data;
if(resData === 'success'){
$('#msgAlert').fadeIn().addClass('success').removeClass('danger').html('withdraw limit updated successfully');
setTimeout(() => {
$('#msgAlert').hide();
GetWithLimitData(withDrawLimitApi);
},1000);
return false;
}
else{
$('#msgAlert').fadeIn().addClass('danger').removeClass('success').html(resData);
setTimeout(() => {
$('#msgAlert').hide();
},1000); 
}
} catch (error) {
console.log('backend withdrawal_limit_bank error : ' + error);
}
}

}

useEffect(() => {
if(withLimit.length > 0){
withLimit.forEach(element => {
if(element.user_role === 'subadmin_role' && element.limitType === 'bank'){
$('#withDraw_min1B').val(element.minWithdraw);
$('#withDraw_max1B').val(element.maxWithdraw);
}
if(element.user_role === 'super_role' && element.limitType === 'bank'){
$('#withDraw_min2B').val(element.minWithdraw);
$('#withDraw_max2B').val(element.maxWithdraw);
}
if(element.user_role === 'master_role' && element.limitType === 'bank'){
$('#withDraw_min3B').val(element.minWithdraw);
$('#withDraw_max3B').val(element.maxWithdraw);
}
if(element.user_role === 'agent_role' && element.limitType === 'bank'){
$('#withDraw_min4B').val(element.minWithdraw);
$('#withDraw_max4B').val(element.maxWithdraw);
}
if(element.user_role === 'customer_role' && element.limitType === 'bank'){
$('#withDraw_min5B').val(element.minWithdraw);
$('#withDraw_max5B').val(element.maxWithdraw);
}
});
}
},[withLimit]);

return (
<>
<div className='limitSet'>

<div className='limitTitle'>
<span>bank withdrawal limit</span>
</div>

<div className='table_responsiveMx'>
<table>
<thead>
<tr>
<th>user role</th>
<th>day</th>
<th>min</th>
<th>max</th>
<th>action</th>
</tr>
</thead>
<tbody>

{
userActive.length > 0 && userActive[0].user_role === 'admin_role' &&
<tr>
<td>sub admin</td>
<td>1 day</td>
<td><input type='number' id='withDraw_min1B' placeholder='min. withdrawal' /></td>
<td><input type='number' id='withDraw_max1B' placeholder='max. withdrawal' /></td>
<td><button onClick={() => updateLimitWithdrawB('subadmin_role','bank')}>update</button></td>
</tr>
}

{
userActive.length > 0 && (userActive[0].user_role === 'admin_role' ||
userActive[0].user_role === 'subadmin_role') &&
<tr>
<td>super master</td>
<td>1 day</td>
<td><input type='number' id='withDraw_min2B' placeholder='min. withdrawal' /></td>
<td><input type='number' id='withDraw_max2B' placeholder='max. withdrawal' /></td>
<td><button onClick={() => updateLimitWithdrawB('super_role','bank')}>update</button></td>
</tr>
}

{
userActive.length > 0 && (userActive[0].user_role === 'admin_role' ||
userActive[0].user_role === 'subadmin_role' || userActive[0].user_role === 'super_role') &&
<tr>
<td>master</td>
<td>1 day</td>
<td><input type='number' id='withDraw_min3B' placeholder='min. withdrawal' /></td>
<td><input type='number' id='withDraw_max3B' placeholder='max. withdrawal' /></td>
<td onClick={() => updateLimitWithdrawB('master_role','bank')}><button>update</button></td>
</tr>
}

{
userActive.length > 0 && (userActive[0].user_role === 'admin_role' ||
userActive[0].user_role === 'subadmin_role' || userActive[0].user_role === 'super_role'
|| userActive[0].user_role === 'master_role') &&
<tr>
<td>agent</td>
<td>1 day</td>
<td><input type='number' id='withDraw_min4B' placeholder='min. withdrawal' /></td>
<td><input type='number' id='withDraw_max4B' placeholder='max. withdrawal' /></td>
<td><button onClick={() => updateLimitWithdrawB('agent_role','bank')}>update</button></td>
</tr>
}

{
userActive.length > 0 && (userActive[0].user_role === 'admin_role' ||
userActive[0].user_role === 'subadmin_role' || userActive[0].user_role === 'super_role'
|| userActive[0].user_role === 'master_role' || userActive[0].user_role === 'agent_role') &&
<tr>
<td>customer</td>
<td>1 day</td>
<td><input type='number' id='withDraw_min5B' placeholder='min. withdrawal' /></td>
<td><input type='number' id='withDraw_max5B' placeholder='max. withdrawal' /></td>
<td><button onClick={() => updateLimitWithdrawB('customer_role','bank')}>update</button></td>
</tr>
}

</tbody>
</table>
</div>
</div>
</>
)
}

export default BankWLimit;
