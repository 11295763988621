import React, { useState, useEffect } from 'react';
import $ from 'jquery';
import { GlobalContext } from '../context/AppContext';

const ViewBank = ({viewCurrBank, setViewCurrBank}) => {

const { bank_data } = GlobalContext();
const [currVbank, setCurrVbank] = useState([]);
const [activeImg, setActiveImg] = useState(null);

const closeViewBank = () => {
$('#viewBankModal').hide();
setViewCurrBank(null);
setCurrVbank([]);
setActiveImg(null); 
}

const closePreviewImg = () => {
setActiveImg(null);   
}

useEffect(() => {
if(viewCurrBank !== null){
if(bank_data.length > 0){
setCurrVbank(bank_data.filter((e) => e.id === viewCurrBank));
}
$('#viewBankModal').show();
}
}, [viewCurrBank, bank_data]);

return (
<>
<div className='modalsSm' id='viewBankModal'>
<div className='modalsSm_content'>
<div className='modalsSm_header'><span>preview bank details</span> <i className='fa fa-close right' onClick={() => closeViewBank()}></i></div>
<div className='modalsSm_body'>

{
currVbank.length > 0 &&
<div className='qrDivWrap bankViewData'>
<div className='bankWhatDiv'>
<div className='bankImgWhat'>
<img src='/images/bank.png' alt='bank' />
<span>banking details</span>
</div>
</div>

<div className='qrHolderDeta'>
<div className='qrHolderForm'>
<label>bank name</label>
<input type='text' defaultValue={currVbank[0].bankName} disabled />
</div>
<div className='qrHolderForm'>
<label>holder name</label>
<input type='text' defaultValue={currVbank[0].holderName} disabled />
</div>
<div className='qrHolderForm'>
<label>account no.</label>
<input type='text' defaultValue={currVbank[0].bankAcNo} disabled />
</div>
<div className='qrHolderForm'>
<label>ifsc code</label>
<input type='text' defaultValue={currVbank[0].bankIfsc} disabled />
</div>
<div className='qrHolderForm'>
<label>amount</label>
<input type='number' defaultValue='1000' disabled />
</div>
{
activeImg === null ?
<div className='qrHolderFile'>
<label><i className='fa fa-plus' />
<input type='file' name='paySS' id='paySS' accept='image/*' onChange={(e) => setActiveImg(e.target.files[0])} />
click here to upload payment screenshot</label>
</div>
:
<div className='previewImgWrap'>
<i className='fa fa-close' onClick={closePreviewImg} />
<div className='previewImg'>
<img src={URL.createObjectURL(activeImg)} alt='previewImg' />
</div>
</div>
}
</div>

<div className='qrTerms'>
<div className='col'>
<i className='fa fa-exclamation-circle' />
</div>
<div className='col'>
<span>Once you make the payment to the above account, Share payment transation screenshot below and confirm successful deposit.</span>
</div>
</div>

<div className='bankOptBtn'>
<button className='bankSubmit'>submit</button>
</div>

</div>
}

</div>
</div>
</div>
</>
)
}

export default ViewBank;
