import React, { useEffect, useState } from 'react';
import $ from 'jquery';
import UpiWLimit from './UpiWLimit';
import BankWLimit from './BankWLimit';

const PaySetting = () => {

const [currWLopt, setCurrWLopt] = useState('wl_upi');

useEffect(() => {
$(document).on('click', '.withDrawL_optBtn button', function(){
$(this).addClass('active').siblings().removeClass('active');
});
},[]);

return (
<>
<div className='paySettings'>
<div className='withDrawLimit'>
<div className='withDrawL_title'>
<span>withdrawal limit options</span>
</div>

<div className='withDrawL_optBtn'>
<button className='active' onClick={() => setCurrWLopt('wl_upi')}>upi</button>
<button onClick={() => setCurrWLopt('wl_bank')}>bank</button>
</div>

<div className='limitSetCont'>
{
currWLopt === 'wl_upi' ? <UpiWLimit /> : <BankWLimit />
}
</div>

</div>
</div>
</>
)
}

export default PaySetting;
