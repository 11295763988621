import React, {useEffect} from 'react';
import $ from 'jquery';
import axios from 'axios';
import { Link, useLocation } from 'react-router-dom';
import { GlobalContext } from '../context/AppContext';

const MainMenu = () => {

const { userActive } = GlobalContext();
const location = useLocation();

const logOut = async() => {
try {
const sendData = await axios.get('/server2/logout');
const resData = await sendData.data;
if(resData === 'success'){
$('#msgAlert').fadeIn().addClass('success').removeClass('danger').html('logout success');
setTimeout(function(){
$('#msgAlert').hide();
window.location.replace('/');
},1000);
return false;
}
else{
$('#msgAlert').fadeIn().addClass('danger').removeClass('success').html(resData);
setTimeout(function(){
$('#msgAlert').hide();
},1000);
}
} catch (error) {
console.log('backend logout-api error : ' + error);
}
}

useEffect(() => {
if(location.pathname === '/dashboard'){
$('.mainMenuLi').removeClass('active');
$('.mainMenuLi1').addClass('active');
}
else if(location.pathname === '/all-users' || location.pathname === '/all-admin'){
$('.mainMenuLi').removeClass('active');
$('.mainMenuLi2').addClass('active');
}
else if(location.pathname === '/my_account'){
$('.mainMenuLi').removeClass('active');
$('.mainMenuLi3').addClass('active');
}
else if(location.pathname === '/sport_report' || location.pathname === '/downline_report'){
$('.mainMenuLi').removeClass('active');
$('.mainMenuLi4').addClass('active');
}
else if(location.pathname === '/bet_list'){
$('.mainMenuLi').removeClass('active');
$('.mainMenuLi5').addClass('active');
}
else if(location.pathname === '/market_analysis'){
$('.mainMenuLi').removeClass('active');
$('.mainMenuLi6').addClass('active');
}
else if(location.pathname === '/user_banking' || location.pathname === '/admin_banking'){
$('.mainMenuLi').removeClass('active');
$('.mainMenuLi7').addClass('active');
}
else if(location.pathname === '/user_commisions' || location.pathname === '/commision_history'){
$('.mainMenuLi').removeClass('active');
$('.mainMenuLi8').addClass('active');
}
else if(location.pathname === '/password_history'){
$('.mainMenuLi').removeClass('active');
$('.mainMenuLi9').addClass('active');
}
else if(location.pathname === '/restore_users'){
$('.mainMenuLi').removeClass('active');
$('.mainMenuLi10').addClass('active');
}
else if(location.pathname === '/bet_setting' || location.pathname === '/game_setting' || location.pathname === '/notification'){
if(userActive.length > 0){
$('.mainMenuLi').removeClass('active');
$('.mainMenuLi11').addClass('active');
}
}
else{
$('.mainMenuLi').removeClass('active');
$('.mainMenuLi12').addClass('active');
}
},[userActive, location]);

return (
<>
<div className='mainMenuDiv'>
<ul className='mainMenuUl'>
<Link to='/dashboard'><li className='mainMenuLi mainMenuLi1'>dashboard</li></Link>
<li className='mainMenuLi relativeLi mainMenuLi2'><span>downline list <i className='fa fa-caret-down'></i></span>
<div className='relativeLi_div'>
<ul className='relativeLi_divUl'>
<Link to='/all-users'><li>user downline</li></Link>
{
userActive.length > 0 && userActive[0].user_role !== 'agent_role' &&
<Link to='/all-admin'><li>all admin downline</li></Link>
}
</ul>
</div>
</li>
<Link to='/my_account'><li className='mainMenuLi mainMenuLi3'>my account</li></Link>
<li className='mainMenuLi relativeLi mainMenuLi4'><span>my report <i className='fa fa-caret-down'></i></span>
<div className='relativeLi_div'>
<ul className='relativeLi_divUl'>
<Link to='/sport_report'><li>event profit/loss</li></Link>
<Link to='/downline_report'><li>downline profit/loss</li></Link>
</ul>
</div>
</li>
<Link to='/bet_list'><li className='mainMenuLi mainMenuLi5'>bet list</li></Link>
<Link to='/market_analysis'><li className='mainMenuLi mainMenuLi6'>market analysis</li></Link>
<li className='mainMenuLi relativeLi mainMenuLi7'><span>banking <i className='fa fa-caret-down'></i></span>
<div className='relativeLi_div'>
<ul className='relativeLi_divUl'>
<Link to='/user_banking'><li>user banking</li></Link>
{
userActive.length > 0 && userActive[0].user_role !== 'agent_role' &&
<Link to='/admin_banking'><li>all admin banking</li></Link>
}
</ul>
</div>
</li>
<li className='mainMenuLi relativeLi mainMenuLi8'><span>commision <i className='fa fa-caret-down'></i></span>
<div className='relativeLi_div'>
<ul className='relativeLi_divUl'>
<Link to='/user_commisions'><li>user commision</li></Link>
<Link to='/commision_history'><li>commision history</li></Link>
</ul>
</div>
</li>
<Link to='/password_history'><li className='mainMenuLi mainMenuLi9'>password history</li></Link>
<Link to='/restore_users'><li className='mainMenuLi mainMenuLi10'>restore user</li></Link>

<li className='mainMenuLi relativeLi mainMenuLi11'><span>settings <i className='fa fa-cog'></i></span>
<div className='relativeLi_div'>
{
userActive.length > 0 && userActive[0].user_role === 'admin_role' ?
<ul className='relativeLi_divUl'>
<Link to='/bet_setting'><li>bet setting</li></Link>
<Link to='/game_setting'><li>game setting</li></Link>
<Link to='/notification'><li>notification</li></Link>
<Link to='/self_deposit'><li>self deposit</li></Link>
<Link to='/settlement'><li>settlement</li></Link>
<Link to='/re_settlement'><li>re-settlement</li></Link>
<Link to='/payment_setting'><li>payment setting</li></Link>
</ul>
:
<ul className='relativeLi_divUl'>
<Link to='/payment_setting'><li>payment setting</li></Link>
</ul>
}
</div>
</li>
<li onClick={() => logOut()} className='mainMenuLi mainMenuLi12'><span>log out <i className='fa fa-sign-out'></i></span></li>
</ul>
</div>
</>
)
}

export default MainMenu;
