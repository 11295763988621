import React, { useEffect, useState } from 'react';
import $ from 'jquery';
import AddUpi from '../../modals/AddUpi';
import { GlobalContext } from '../../context/AppContext';
import ViewUpi from '../../modals/ViewUpi';
import DelUpi from '../../modals/DelUpi';

const Upi = () => {

const { upi_data, userActive } = GlobalContext();
const [currUpi, setCurrUpi] = useState([]);
const [currPage, setCurrPage] = useState(1);
const [sortPage, setSortPage] = useState(25);
const recordPage = sortPage;
const lastIndex = currPage * recordPage;
const firstIndex = lastIndex - recordPage;
const records = currUpi.slice(firstIndex, lastIndex);
const pageLength = Math.ceil(currUpi.length / recordPage);
const pageNumber = [...Array(pageLength + 1).keys()].slice(1);

const [viewCurrUpi ,setViewCurrUpi] = useState(null);
const [delCurrUpi ,setDelCurrUpi] = useState(null);

const prevPage = () =>{
if(parseInt(currPage) !== 1){
setCurrPage(parseInt(currPage) - 1);
}
}
    
const nextPage = () =>{
if(parseInt(currPage) !== parseInt(pageLength)){
setCurrPage(parseInt(currPage) + 1);
}
}

const handleSort = (e) =>{
setSortPage(e.target.value);
}

const addUpi = () => {
$('#addUpiModal').show();
}

useEffect(() => {
if(upi_data.length > 0 && userActive.length > 0){
setCurrUpi(upi_data.filter((e) => e.customer === userActive[0].customer));
}
else{
setCurrUpi([]); 
}
}, [upi_data, userActive]);

return (
<>
<div className='paymentContents'>
<div className='rightBtn_pay'>
<button onClick={addUpi}><i className='fa fa-plus' /> add upi</button>
</div>

<div className='paymentDataDiv'>
<div className='stateBody'>
<div className='sort-pageDiv'>
<div className='sortInput'>
<label>sort page :</label> 
<select className='sm-select' onChange={(e) => handleSort(e)}>
<option>25</option>
<option>50</option>
<option>100</option>
<option>250</option>
</select>
</div>
</div>

<div className='totalRecords_div'>
<span>display {records.length} records out of total {currUpi.length} records...</span>
</div>

<div className='table-responsive'>
<table className='paymentTable'>
<thead>
<tr>
<th>upi account name</th>
<th>upi account id</th>
<th>upi account type</th>
<th>deposit min</th>
<th>deposit max</th>
<th>created date</th>
<th>action</th>
</tr>
</thead>
<tbody>
{
records.length > 0 ? records.map((data, index) => {
return(
<tr key={index}>
<td>{data.upiAcName}</td>
<td>{data.upiAcId}</td>
<td>{data.upiType}</td>
<td>{data.upiMin}</td>
<td>{data.upiMax}</td>
<td>{data.datetime}</td>
<td>
<button className='tdBtn' onClick={() => setViewCurrUpi(data.id)}><i className='fa fa-eye' /></button>
<button className='tdBtn' onClick={() => setDelCurrUpi(data.id)}><i className='fa fa-trash' /></button>
</td>
</tr>
)
})
:
<tr>
<td colSpan='9' className='tdNo'><span className='tdNoRecord'>no records founds...</span></td>
</tr>
}
</tbody>
</table>
</div>

<div className='pagiNation_wrap'>
<div className='paginationDrop'>
<div className='sortInput'>
<label>page no :</label>    
<select onChange={(e) => setCurrPage(e.target.value)}>
{
pageNumber.map((data, index) =>{
return(
<option key={index}>{data}</option>
)
})
}
</select>
</div>
</div>

<ul>
<li className='pagination-btn radiusLeft' onClick={() => prevPage()}>Prev</li>
<li><span className='pagination-items'>{pageNumber.length > 0 ? currPage : 0} of {pageNumber.length}</span></li>
<li className='pagination-btn radiusRight' onClick={() => nextPage()}>Next</li>
</ul>
</div>
</div>
</div>
</div>

<AddUpi />
<ViewUpi viewCurrUpi={viewCurrUpi} setViewCurrUpi={setViewCurrUpi} />
<DelUpi delCurrUpi={delCurrUpi} setDelCurrUpi={setDelCurrUpi} />
</>
)
}

export default Upi;
