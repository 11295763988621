import React, { useState, useEffect } from 'react';
import $ from 'jquery';
import { useNavigate } from 'react-router-dom';
import AddMaster from '../modals/AddMaster';
import CreditModal from '../modals/CreditModal';
import PartnerModal from '../modals/PartnerModal';
import Banking from '../modals/Banking';
import Status from '../modals/Status';
import SportsModal from '../modals/SportsModal';
import TotalBal from './TotalBal';
import Remove from '../modals/Remove';
import StatusAlert from '../pages/StatusAlert';
import { GlobalContext } from '../context/AppContext';

const userExpo_api = '/server2/exposure-user';
const usersList_api = '/server2/users-list';
const allUsers_api = '/server2/users-list-all';

const SubAdmin = () => {

const { userList, useExpo, allUsers, getUserLists, userExpo, userAll, userList_load, expoLoad, loadAllUser } = GlobalContext();
const [isChecked, setIsChecked] = useState(false);
const [currUsers, setCurrUsers] = useState([]);
const [currPage, setCurrPage] = useState(1);
const [sortPage, setSortPage] = useState(25);
const recordPage = sortPage;
const lastIndex = currPage * recordPage;
const firstIndex = lastIndex - recordPage;
const records = currUsers.slice(firstIndex, lastIndex);
const pageLength = Math.ceil(currUsers.length / recordPage);
const pageNumber = [...Array(pageLength + 1).keys()].slice(1);

const [sportUser, setSportUser] = useState(null);
const Navigate = useNavigate();

const showMasterAdd = () => {
$('#masterModal').show();
}

const reloadMaster = () => {
getUserLists(usersList_api);
userExpo(userExpo_api);
userAll(allUsers_api);
}

const prevPage = () =>{
if(parseInt(currPage) !== 1){
setCurrPage(parseInt(currPage) - 1);
}
}

const nextPage = () =>{
if(parseInt(currPage) !== parseInt(pageLength)){
setCurrPage(parseInt(currPage) + 1);
}
}

const handleSort = (e) =>{
setSortPage(e.target.value);
}

const creditRef = (id,user,credit) => {
$('#creditModal').show();
$('#userEditM').text(user);
$('#oldCreditRef').text(credit);
$('#creditId').val(id);
}

const partnerRef = (id,user,partner) => {
$('#partnerModal').show();
$('#userPartM').text(user);
$('#oldPartnerRef').text(partner);
$('#partnerId').val(id);
}

const userBanking =(id,role,name,bal,total) => {
$('#bankingModal').show();
var a = role.split('_');
$('#cuurBankUserRole').text(a[0]);
$('#cuurBankUser').text(name);
$('#clientBalB').text(parseFloat(total.toFixed(2)).toLocaleString());
$('#availBal').val(bal);
$('#bankingId').val(id);
}

const userStatus = (id,role,name,status) => {
$('#statusModal').show();
var a = role.split('_'); 
$('#statusUserRole').text(a[0]);
$('#cuurStatusUser').text(name);
$('#statusId').val(id);
$('#statusUser').text(status);
}

const userSports = (role,user) => {
$('#sportsModal').show();
var a = role.split('_');
$('#sportsUserRole').text(a[0]);
$('#sportsUser').text(user);
setSportUser(user);
}

const userProfile = (user,userRole) => {
Navigate('/user_profile', {state:{name:user, role:userRole}});
}

const chipsSummary = () => {
setIsChecked(!isChecked); 
localStorage.setItem('chipsKey', JSON.stringify(isChecked));
}

const openSub = (user, userRole) => {
Navigate('/all-admin/users', {state:{name:user, role:userRole}});  
}

const removeUsers = (id,expo,bal,role,user) => {
// alert(expo +' -- '+ bal +' -- '+ role +' -- '+ user);
if(expo > 0){
$('#msgAlert').fadeIn().addClass('danger').removeClass('success').html('waiting for clear exposure');
setTimeout(function(){
$('#msgAlert').hide();
},1000);
return false;
}
if(bal > 0){
$('#msgAlert').fadeIn().addClass('danger').removeClass('success').html('withdrawal all avail. balance');
setTimeout(function(){
$('#msgAlert').hide();
},1000);
return false;
}
else{
const a = role.split('_');
$('#removeModal').show();
$('#removeUserId').val(id);
$('#roleRemove').text(a[0]);
$('#userRemove').text(user);
}
}

const searchUsers = (name) => {
setCurrUsers(userList.filter((e) => e.user_role.trim() !== 'customer_role' && e.user_role.trim() !== 'demo_role' && e.customer.includes(name)));
}

useEffect(() => {
if(userList.length > 0){
setCurrUsers(userList.filter((e) => e.user_role.trim() !== 'customer_role' && e.user_role.trim() !== 'demo_role'));
}
},[userList]);

useEffect(() => {
currUsers.forEach((e) => {
let a = 0;
let b = 0;
let c = 0;

if(e.user_role === 'agent_role'){
c = allUsers.length > 0 && allUsers.filter((f) => f.agent === e.customer).reduce((g, h) => {
g = g + h.total_balance;
return g;
}, 0);
}
else if(e.user_role === 'subadmin_role'){
c = allUsers.length > 0 && allUsers.filter((f) => f.sub_admin === e.customer).reduce((g, h) => {
g = g + h.total_balance;
return g;
}, 0);
}
else if(e.user_role === 'super_role'){
c = allUsers.length > 0 && allUsers.filter((f) => f.super_master === e.customer).reduce((g, h) => {
g = g + h.total_balance;
return g;
}, 0);
}
else if(e.user_role === 'master_role'){
c = allUsers.length > 0 && allUsers.filter((f) => f.master === e.customer).reduce((g, h) => {
g = g + h.total_balance;
return g;
}, 0);
}

//console.log(c);

if(c !== false){
if(e.creditRefrence > (e.total_balance + c)){
a = (e.total_balance + c) - e.creditRefrence;
}else{
a = (e.total_balance + c) - e.creditRefrence;
}

if(isChecked){
b = (a * e.partnership) / 100;
if(b > 0){
$('#ChipsFund_'+e.id).addClass('successTxt').text('('+parseFloat(b.toFixed(2)).toLocaleString()+')');
}
else{
$('#ChipsFund_'+e.id).addClass('dangerTxt').text('('+parseFloat(b.toFixed(2)).toLocaleString()+')');  
}
}
else{
if(a > 0){
$('#ChipsFund_'+e.id).addClass('successTxt').removeClass('dangerTxt').text('('+parseFloat(a.toFixed(2)).toLocaleString()+')');
}else{
$('#ChipsFund_'+e.id).addClass('dangerTxt').removeClass('successTxt').text('('+parseFloat(a.toFixed(2)).toLocaleString()+')');  
} 
}
}

});
},[currUsers, allUsers, isChecked]);

useEffect(() => {
let currChips = JSON.parse(localStorage.getItem('chipsKey'));
if(currChips === false){
setIsChecked(true); 
}else{
setIsChecked(false);    
}
},[]);

if(userList_load && expoLoad && loadAllUser){
return(
<div className='mainLoader'>
<div className='mainLoaderWrap'>
<div className='dotWrap'>
<span></span>
<span></span>
</div>
<span className='loadingTxt'>Loading...</span>
</div>
</div>  
)
}

return (
<>
<div className='content-body'>
<div className='containers'>
<StatusAlert />
<div className='firstColDiv'>
<div className='content'>

<div className='chipsSwitch'>
<span>chips summary</span>
<label className='chipsSummary'>
<input type='checkbox' checked={isChecked} onChange={chipsSummary} />
<span className='chipsSlider'></span>
</label>
</div>

<div className='addUserBtnDiv'>
<button onClick={() => showMasterAdd()}><i className='fa fa-user-plus'></i> add master</button>
<button onClick={() => reloadMaster()}><img src='/images/refresh-arrow.png' alt='reload' /></button>
</div>

</div>
</div>

<TotalBal check={isChecked} />

<div className='firstColDiv tableWrap'>

<div className='sort-pageDiv'>
<div className='sortInput'>
<label>sort page :</label> 
<select className='sm-select' onChange={(e) => handleSort(e)}>
<option>25</option>
<option>50</option>
<option>100</option>
<option>250</option>
</select>
</div>

<input type='text' name='search_data' placeholder='search users...' onChange={(e) => searchUsers(e.target.value)} />
</div>

<div className='totalRecords_div'>
<span>display {records.length} records out of total {currUsers.length} records...</span>
</div>

<div className='table-responsive'>
<table className='usersTable'>
<thead>
<tr>
<th>username</th>
<th>currency</th>
<th>credit ref.</th>
<th>partnership</th>
<th>balance</th>
<th>exposure</th>
<th>avail. bal.</th>
<th>ref. p/l</th>
<th>status</th>
<th>action</th>
</tr>
</thead>
<tbody>
{
records.length > 0 ? records.map((data, index) => {
var currStatus = null;
let currExpo = 0;
let subAdmUsrBal = 0;
if(data.status === 'active'){
currStatus = <span className='statusTd actGreen'>active</span>;   
}
else if(data.status === 'suspended'){
currStatus = <span className='statusTd actRed'>suspended</span>;   
}
else{
currStatus = <span className='statusTd actYell'>locked</span>;   
}

if(data.user_role === 'agent_role'){
currExpo = useExpo.length > 0 && useExpo.filter((e) => e.agent === data.customer).reduce((k, l) => {
k = k + l.exposure;
return k;
},0);

subAdmUsrBal = allUsers.length > 0 && allUsers.filter((e) => e.agent === data.customer).reduce((c, d) => {
c = c + d.total_balance;
return c;
},0);
}
else if(data.user_role === 'subadmin_role'){
currExpo = useExpo.length > 0 && useExpo.filter((e) => e.sub_admin === data.customer).reduce((k, l) => {
k = k + l.exposure;
return k;
},0);

subAdmUsrBal = allUsers.length > 0 && allUsers.filter((e) => e.sub_admin === data.customer).reduce((c, d) => {
c = c + d.total_balance;
return c;
},0);
}
else if(data.user_role === 'super_role'){
currExpo = useExpo.length > 0 && useExpo.filter((e) => e.super_master === data.customer).reduce((k, l) => {
k = k + l.exposure;
return k;
},0);

subAdmUsrBal = allUsers.length > 0 && allUsers.filter((e) => e.super_master === data.customer).reduce((c, d) => {
c = c + d.total_balance;
return c;
},0);
}
else if(data.user_role === 'master_role'){
currExpo = useExpo.length > 0 && useExpo.filter((e) => e.master === data.customer).reduce((k, l) => {
k = k + l.exposure;
return k;
},0);

subAdmUsrBal = allUsers.length > 0 && allUsers.filter((e) => e.master === data.customer).reduce((c, d) => {
c = c + d.total_balance;
return c;
},0);
}

//console.log(currExpo);

return(
<tr key={index}>
<td>
<div className='flex_td'>
{
data.coming === 'online' ? 
<span className='onlineAct'></span>
:
<span className='offlineAct'></span>
}

<span className='roleTxt'>{data.user_role.slice(0, 3)}</span> <span className='links' onClick={() => openSub(data.customer,data.user_role)}>{data.customer}</span>
</div>
</td>
<td>{data.currency}</td>
<td>{parseFloat(data.creditRefrence.toFixed(2)).toLocaleString()} <i className='fa fa-edit editIcon' onClick={() => creditRef(data.id,data.customer,data.creditRefrence)}></i></td>
<td>{data.partnership} <i className='fa fa-edit editIcon' onClick={() => partnerRef(data.id,data.customer,data.partnership)}></i></td>
<td>{parseFloat((data.total_balance + subAdmUsrBal).toFixed(2)).toLocaleString()}</td>
<td><span className='dangerTxt'>({parseFloat((currExpo !== false ? currExpo : 0).toFixed(2)).toLocaleString()})</span></td>
<td>{parseFloat(data.total_balance.toFixed(2)).toLocaleString()}</td>
<td className='ChipsFund' id={'ChipsFund_'+data.id}>{parseFloat(data.creditRefrence) > parseFloat(data.total_balance + subAdmUsrBal) ? <span className='dangerTxt'>({parseFloat((parseFloat(data.total_balance + subAdmUsrBal) - parseFloat(data.creditRefrence)).toFixed(2)).toLocaleString()})</span> : <span className='successTxt'>({parseFloat((parseFloat(data.total_balance + subAdmUsrBal) - parseFloat(data.creditRefrence)).toFixed(2)).toLocaleString()})</span>}</td>
<td>{currStatus}</td>
<td>
<button onClick={() => userBanking(data.id,data.user_role,data.customer,data.total_balance,(data.total_balance + subAdmUsrBal))} className='tdBtn'><img src='/images/bank-building.png' alt='bank-building' /></button>
<button onClick={() => userStatus(data.id,data.user_role,data.customer,data.status)} className='tdBtn'><img src='/images/settings.png' alt='bank-building' /></button>
<button onClick={() => userProfile(data.customer,data.user_role)} className='tdBtn'><img src='/images/user.png' alt='bank-building' /></button>
<button  onClick={() => userSports(data.user_role,data.customer)} className='tdBtn'><img src='/images/lock.png' alt='bank-building' /></button>
<button onClick={() => removeUsers(data.id,currExpo,data.total_balance,data.user_role,data.customer)} className='tdBtn'><img src='/images/trash-bin.png' alt='bank-building' /></button>
</td>
</tr>
)
})
:
<tr>
<td colSpan='10' className='tdNo'><span className='tdNoRecord'>no records founds...</span></td>
</tr>
}
</tbody>
</table>
</div>

<div className='pagiNation_wrap'>
<div className='paginationDrop'>
<div className='sortInput'>
<label>page no :</label>    
<select onChange={(e) => setCurrPage(e.target.value)}>
{
pageNumber.map((data, index) =>{
return(
<option key={index}>{data}</option>
)
})
}
</select>
</div>
</div>

<ul>
<li className='pagination-btn radiusLeft' onClick={() => prevPage()}>Prev</li>
<li><span className='pagination-items'>{pageNumber.length > 0 ? currPage : 0} of {pageNumber.length}</span></li>
<li className='pagination-btn radiusRight' onClick={() => nextPage()}>Next</li>
</ul>
</div>

</div>

</div>
</div>

<AddMaster />
<CreditModal />
<PartnerModal />
<Banking />
<Status />
<SportsModal user={sportUser} />
<Remove />

</>
)
}

export default SubAdmin;
