import React from 'react';
import $ from 'jquery';
import axios from 'axios';
import { GlobalContext } from '../context/AppContext';

const passHisApi = '/server2/password-history';

const ChangePass2 = () => {

const { userActive, getPassHis } = GlobalContext();

const closePasswordMod2 = () => {
$('#changePassModal2').hide();
$('.clearChgP').val('');
}

const Change_Pass2 = async() => {
var id = $('#changePassId2').val();
var customer = $('#PassUser2').val();
var yourPass = $('#yourPass2').val();
var newPass = $('#newPass2').val();
var confPass = $('#confPass2').val();

if(yourPass !== (userActive.length > 0 ? userActive[0].password : null)){
$('#msgAlert').fadeIn().addClass('danger').removeClass('success').html('admin password no match');
setTimeout(function(){
$('#msgAlert').hide();
},1000);
return false;
}
if(newPass.length < 6){
$('#msgAlert').fadeIn().addClass('danger').removeClass('success').html('min. 6 digit password required');
setTimeout(function(){
$('#msgAlert').hide();
},1000);
return false;
}
if(newPass !== confPass){
$('#msgAlert').fadeIn().addClass('danger').removeClass('success').html('confirm password no match');
setTimeout(function(){
$('#msgAlert').hide();
},1000);
return false;
}
else{
try {
const sendData = await axios.post('/server2/updateAdmin_password', {
id: id,
customer: customer,
confPass: confPass
});
const resData = await sendData.data;
if(resData === 'success'){
$('#msgAlert').fadeIn().addClass('success').removeClass('danger').html('password changed successfully');
setTimeout(function(){
$('#msgAlert').hide();
getPassHis(passHisApi);
$('#changePassModal2').hide();
$('.clearChgP').val('');
},1000);
return false; 
}
else{
$('#msgAlert').fadeIn().addClass('danger').removeClass('success').html(resData);
setTimeout(function(){
$('#msgAlert').hide();
},1000);
}
} catch (error) {
console.log('backend change-password-api error : ' + error);
}
}
}

return (
<>
<div className='modalsSm' id='changePassModal2'>
<div className='modalsSm_content'>
<div className='modalsSm_header'><span>change password</span> <i className='fa fa-close right' onClick={() => closePasswordMod2()}></i></div>
<div className='modalsSm_body'>

<div className='formDiv'>
<div className='formControls flexControl'>
<div><label>your password </label></div>
<div>
<input className='clearChgP' type='hidden' id='changePassId2' />
<input className='clearChgP' type='hidden' id='PassUser2' />
<input className='clearChgP' type='password' name='yourPass2' id='yourPass2' placeholder='your password...' />
</div>
</div>
<div className='formControls flexControl'>
<div><label>new password *</label></div>
<div><input className='clearChgP' type='password' name='newPass2' id='newPass2' placeholder='new password...' /></div>
</div>
<div className='formControls'>
<div><label>conf. password *</label></div>
<div><input className='clearChgP' type='text' name='confPass2' id='confPass2' placeholder='confirm password...' /></div>
</div>
<div className='formControlsSm'>
<button onClick={() => Change_Pass2()}>confirm</button>
<button onClick={() => closePasswordMod2()}>cancel</button>
</div>
</div>

</div>
</div>
</div>
</>
)
}

export default ChangePass2;
